<template>
  <!-- <v-dialog
      v-model="dialogtimeslot"
      max-width="900px"
      :retain-focus="false"
      persistent
    > -->
  <v-form ref="createForm" v-model="formDD.is_Validate" :lazy-validation="lazy">
    <v-row align="end" class="ml-10">
      <v-avatar size="90">
        <v-img
          :src="itemTimeSlotEdit.image_profile"
          max-width="80px"
          max-height="80px"
        ></v-img>
      </v-avatar>
      <h1>
        {{ itemTimeSlotEdit.first_name }}
        {{ itemTimeSlotEdit.last_name }}
      </h1>
    </v-row>
    <v-row class="pa-4" style="background: white">
      <v-col cols="6">
        <div class="fn20-weight7">Time Slot</div>
      </v-col>
      <!-- <v-divider></v-divider> -->

      <v-stepper v-model="start" vertical width="100%">
        <v-stepper-step :complete="start > 1" step="1" class="fn16">
          Time Slot
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-row class="py-6 px-5">
            <v-col cols="12" class="pa-0">
              <span>Date Available</span>
            </v-col>
            <v-row class="my-4" no-gutters>
              <v-col cols="3">
                <v-checkbox
                  v-model="itemTimeSlotEdit.is_sun"
                  dense
                  label="Sunday"
                  @click="ChangeDateSlot(itemTimeSlotEdit)"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="itemTimeSlotEdit.is_mon"
                  dense
                  label="Monday"
                  @click="ChangeDateSlot(itemTimeSlotEdit)"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="itemTimeSlotEdit.is_tue"
                  dense
                  label="TuesDay"
                  @click="ChangeDateSlot(itemTimeSlotEdit)"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="itemTimeSlotEdit.is_wed"
                  dense
                  label="WednesDay"
                  @click="ChangeDateSlot(itemTimeSlotEdit)"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="itemTimeSlotEdit.is_thu"
                  dense
                  label="Thursday"
                  @click="ChangeDateSlot(itemTimeSlotEdit)"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="itemTimeSlotEdit.is_fri"
                  dense
                  label="Friday"
                  @click="ChangeDateSlot(itemTimeSlotEdit)"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="itemTimeSlotEdit.is_sat"
                  dense
                  label="Saturday"
                  @click="ChangeDateSlot(itemTimeSlotEdit)"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-col cols="12" class="pa-0">
              <v-btn
                v-if="itemTimeSlotEdit.is_sun"
                outlined
                :color="itemTimeSlotEdit.is_sun ? 'blue' : ''"
                class="ma-1"
                small
                @click="SelectDay('SUN')"
                >Sunday</v-btn
              >
              <v-btn
                v-if="itemTimeSlotEdit.is_mon"
                outlined
                :color="itemTimeSlotEdit.is_mon ? 'blue' : ''"
                class="ma-1"
                small
                @click="SelectDay('MON')"
                >Monday</v-btn
              >
              <v-btn
                v-if="itemTimeSlotEdit.is_tue"
                outlined
                :color="itemTimeSlotEdit.is_tue ? 'blue' : ''"
                class="ma-1"
                small
                @click="SelectDay('TUE')"
                >Tuesday</v-btn
              >
              <v-btn
                v-if="itemTimeSlotEdit.is_wed"
                outlined
                :color="itemTimeSlotEdit.is_wed ? 'blue' : ''"
                class="ma-1"
                small
                @click="SelectDay('WED')"
                >Wednesday</v-btn
              >
              <v-btn
                v-if="itemTimeSlotEdit.is_thu"
                outlined
                :color="itemTimeSlotEdit.is_thu ? 'blue' : ''"
                class="ma-1"
                small
                @click="SelectDay('THU')"
                >Thursday</v-btn
              >
              <v-btn
                v-if="itemTimeSlotEdit.is_fri"
                outlined
                :color="itemTimeSlotEdit.is_fri ? 'blue' : ''"
                class="ma-1"
                small
                @click="SelectDay('FRI')"
                >Friday</v-btn
              >
              <v-btn
                v-if="itemTimeSlotEdit.is_sat"
                outlined
                :color="itemTimeSlotEdit.is_sat ? 'blue' : ''"
                class="ma-1"
                small
                @click="SelectDay('SAT')"
                >Saturday</v-btn
              >
            </v-col>

            <v-col cols="12"><v-divider /></v-col>
            <v-col cols="12" class="pa-0">
              <span>Select Time</span>
            </v-col>
            <v-row>
              <v-col cols="3" v-for="(i, index) in timeslots" :key="index">
                <v-col cols="12" md="12">
                  <!-- v-for="(i, index) in formDD.timeslots" :key="index" -->
                  <v-chip
                    :dark="i.is_avalible ? true : false"
                    class="ma-1 mr-6"
                    :color="i.is_avalible ? '#E8F0FF' : ''"
                    :outlined="i.is_avalible ? false : true"
                    @click="ChangeTimeSlot(i)"
                    ><span
                      :style="i.is_avalible ? 'color:#1A347F' : 'color: grey'"
                    >
                      {{ i.day }}{{ i.time }}
                    </span></v-chip
                  >
                </v-col>
              </v-col>
            </v-row>
          </v-row>
        </v-stepper-content>
      </v-stepper>

      <v-col cols="12" class="px-0 pb-0" align="center" justify="center">
        <v-btn
          class="rounded-xl mr-2"
          style="color: #9bbef2"
          outlined
          width="250"
          @click="goback()"
        >
          <span class="text-center fn14-weight7"> back </span>
        </v-btn>
        <v-btn
          dark
          class="rounded-xl"
          style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
          width="250"
          @click="goback()"
        >
          <span class="text-center fn14-weight7"> Save </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
  <!-- </v-dialog> -->
</template>
  <script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
  },
  props: {
    // dialogtimeslot: {
    //   type: Boolean,
    //   default: true,
    // },
    //   formDD: Object,
    //   itemTimeSlotEdit: Object,
    items: Object,
  },
  data() {
    return {
      formDD: [],
      timeslots: [],
      itemTimeSlotEdit: [],
      dayselected: "",
      defaultImage: require("@/assets/images/doctor.png"),
      is_Validate: true,
      lazy: false,
      dialogtimeslot: false,
      start: 1,
      product: "",
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      bannerImg: "",
      showImage: "",
      imageType: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
          pic1: "",
          picture_1: "",
          showImage: "",
        },
      },

      idEdit: "",
      selectedType: "",
      // form: {
      //   first_name: "first_name",
      //   last_name: "last_name",
      //   description: "",
      //   image_profile: "",
      //   status: "",
      //   is_sun: true,
      //   is_mon: true,
      //   is_tue: true,
      //   is_wed: false,
      //   is_thu: false,
      //   is_fri: false,
      //   is_sat: false,
      // },
    };
  },
  watch: {
    "formDD.date"(val) {
      console.log("val[0]", val[0]);
      console.log("val[1]", val[1]);
    },
    // items: {
    //   handler(val) {
    //     if (this.isEdit === "edit") {
    //       this.getDoctorById(val.id);
    //       console.log(val.id);
    //     }
    //   },
    //   deeps: true,
    // },
  },
  // computed: {
  //   "formDD.showdate"() {
  //     return this.formDD.showDate[0], this.formDD.showDate[1];
  //   },
  // },
  created() {
    var doctorItem = JSON.parse(
      Decode.decode(localStorage.getItem("itemTimeSlotEdit"))
    );
    console.log("itemTimeSlotEdit", doctorItem);
    this.getDoctorById(doctorItem.id);
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    async ChangeDateSlot(form) {
      console.log("form", form);
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/doctors/${this.itemTimeSlotEdit.id}`,
        form,
        auth
      );
      console.log("ChangeDateSlot", response);
    },
    async ChangeTimeSlot(i) {
      console.log("i", i);
      i.is_avalible = !i.is_avalible;
      const data = {
        is_avalible: i.is_avalible,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/timeslots/${i.id}`,
        data
      );
      console.log("ChangeTimeSlot", response);
    },
    async SelectDay(dayselected) {
      // this.dayselected = dayselected;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/timeslots?doctorId=${this.itemTimeSlotEdit.id}&day=${dayselected}`
      );
      this.timeslots = response.data.data;

      console.log("this.formDD.timeslots", this.timeslots);
      //   console.log("dayselected", dayselected);
    },
    RemoveImage() {
      this.formDD.picture_1.splice(1, 1);
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    async showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;

      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(element);
        // reader.onload = () => {
        //   this.pic1 = reader.result;
        //   this.showImage = URL.createObjectURL(element);
        // };
        console.log(element);

        // var user = JSON.parse(
        //   Decode.decode(localStorage.getItem("userBiAdmin"))
        // );
        // const auth = {
        //   headers: {
        //     Authorization: `Bearer ${user.token}`,
        //   },
        // };

        // const response = await this.axios.get(
        //   // `${process.env.VUE_APP_API}/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   `https://service.biahmemberclub.com/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   auth
        // );

        // console.log("response", response);
        // console.log("url", response.data);
        let formData = new FormData();
        formData.append("files", element);
        const responseUrl = await this.axios.post(
          `https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/files/uploadFormData`,
          formData
        );
        console.log("responseUrl", responseUrl.data.data);
        // this.imageType = element.type;
        this.showImage = responseUrl.data.data.url;
        // /reward/upload-url-img/?contentType=jpg&image_name=TEST_IMG
      }
    },
    doRequire() {
      if (this.$refs.createForm.validate(true)) {
        this.start = 2;
      }
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;

      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(element);
        // reader.onload = () => {
        //   this.pic1 = reader.result;
        //   this.showImage = URL.createObjectURL(element);
        // };
        console.log(element);

        // var user = JSON.parse(
        //   Decode.decode(localStorage.getItem("userBiAdmin"))
        // );
        // const auth = {
        //   headers: {
        //     Authorization: `Bearer ${user.token}`,
        //   },
        // };

        // const response = await this.axios.get(
        //   // `${process.env.VUE_APP_API}/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   `https://service.biahmemberclub.com/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   auth
        // );

        // console.log("response", response);
        // console.log("url", response.data.url);
        let formData = new FormData();
        formData.append("files", element);
        const responseUrl = await this.axios.post(
          `https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/files/uploadFormData`,
          formData
        );
        console.log("responseUrl", responseUrl.data.data);
        this.imageType = element.type;
        this.showImage = responseUrl.data.data.url;
        // /reward/upload-url-img/?contentType=jpg&image_name=TEST_IMG
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.formDD.bannerImg.splice(1, 1);
    },

    cancel() {
      this.start = 1;
      this.showImage = "";
      this.$emit("close");

      if (this.isEdit !== "edit") {
        this.$emit("clear");
      }
    },

    async getDoctorById(id) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/doctors/` + id
      );
      this.itemTimeSlotEdit = response.data.data;
      console.log("responseById", response);
    },
    convertDate(val) {
      return moment(val).format("MM-DD-YYYY");
    },
  },
};
</script>
  
  