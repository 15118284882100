<template>
  <!-- <v-row
    style="background-color: white"
    align="center"
    justify="center"
    class="text-center"
  > -->
  <!-- <v-form> -->
  <v-app
    style="background-color: white"
    align="center"
    justify="center"
    class="text-center pa-4"
  >
    <div class="page">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-row justify="center">
            <v-img
              src="../assets/liff-logo.png"
              max-height="240px"
              max-width="150px"
            />
          </v-row>
        </v-col>
        <br />
        <br />
        <br />
        <br />
        <v-col cols="12">
          <span
            style="color: #1a347f; font-weight: 900; font-size: 32px;-webkit-center"
            >Set Up Password</span
          >
          <br />
          <br />
          <span
            style="color: #1A347F; font-weight: 400; font-size: 20px;-webkit-center"
          ></span>
        </v-col>
      </v-row>

      <v-row align="start" justify="center" class="text-left" dense no-gutters>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <span style="color: #1a347f; font-weight: 900; font-size: 16px"
                >Hello, NAME_PLACEHOLDER</span
              >
            </v-col>
            <v-col cols="12">
              <span style="color: #1a347f; font-size: 14px; font-weight: 400"
                >Username/login: EMAIL_PLACEHOLDER</span
              >
            </v-col>
            <v-col cols="12">
              <span style="color: #1a347f; font-size: 14px; font-weight: 400"
                >It seems you forgot your login details. A request to reset your
                password has been received. For security reasons the initial
                password cannot be sent over email, you must reset it.</span
              >
            </v-col>
            <v-col cols="12">
              <span style="color: #1a347f; font-size: 14px; font-weight: 400"
                >Please use the link below to reset your password:
              </span>
            </v-col>
            <v-col cols="12" class="my-6">
              <v-row justify="center">
                <v-btn
                  dark
                  class="rounded-xl px-12"
                  width="171"
                  height="39"
                  style="
                    background: linear-gradient(268.1deg, #07204b, #1a347f);
                  "
                  @click="submit()"
                  >Reset Password</v-btn
                >
              </v-row>
            </v-col>
            <v-col cols="12">
              <span style="color: #1a347f; font-size: 14px; font-weight: 400"
                >If you did not initiate this password request, please ignore
                the message. It's often a good security measure to change your
                password often and avoid using the same password on several
                accounts.</span
              >
            </v-col>
            <v-col cols="12">
              <span style="color: #1a347f; font-size: 14px; font-weight: 400">
                Why you received this notification? It's possible another user
                entered your email address or username by mistake. If you have a
                company account, you might want to check if one of your
                colleagues did not reset the password.</span
              >
            </v-col>
            <v-col cols="12" class="mt-6">
              <span style="color: #1a347f; font-size: 12px; font-weight: 900">
                Best Regards,
              </span>
              <br />
              <span style="color: #1a347f; font-size: 12px; font-weight: 900">
                Boehringer Ingelheim Team</span
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center" class="text-center" dense no-gutters>
        <v-col cols="12">
          <span style="font-size: 12px; color: #1a347f; font-weight: 900">
            Email: info@biah-livestockth.com
          </span>
          <br />
          <span style="font-size: 12px; color: #1a347f; font-weight: 900">
            Boehringer Ingelheim Animal Health (Thailand) Limited.
          </span>
          <br />
          <span style="font-size: 12px; color: #1a347f; font-weight: 900">
            2922/193 - 195 Charn Issara Tower II, 9th Floor, New Petchburi Road,
          </span>
          <br />
          <span style="font-size: 12px; color: #1a347f; font-weight: 900">
            Bangkapi, Huaykwang, Bangkok 10310
          </span>
        </v-col>
      </v-row>
      <!-- <br />
    <br /> -->
    </div>
    <v-row dense>
      <footerPage />
    </v-row>
  </v-app>
</template>


<script>
import { Decode, Encode } from "@/services";
import footerPage from "@/components/Shared/footer";
export default {
  components: {
    footerPage,
  },
  data() {
    return {
      Livestock: false,
      Pet: false,
      Poultry: false,
      selected: "",
      password: "",
      username: "",
      confirmpassword: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phone: [
          (v) =>
            (v.length <= 10 && v.length >= 9) ||
            "หมายเลขโทรศัพท์ต้องมี 9-10 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน",
        ],

        password: [
          (v) =>
            v.length >= 8 ||
            " At least 8 characters—the more characters, the better",
          (v) => !!v || "Password is required",
          (v) =>
            !!v.match(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
            ) || "Your password is invalid according to the format.",
        ],

        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "This field is required",
        ],

        confirmPasswordRules: [(v) => !!v || "Password is required"],
      },
      selectedType: "",
    };
  },
  computed: {
    // passwordConfirmationRule() {
    //   return () =>
    //     this.password == this.confirmPassword || "Your password doesn't match";
    // },
  },
  created() {
    this.selectedType = this.$router.currentRoute.query.type;
    this.username = this.$router.currentRoute.query.email;
    console.log("this.selectedType", this.selectedType);
    // if (Object.prototype.hasOwnProperty.call(localStorage, "userBiAdmin")) {
    //   var userData = JSON.parse(
    //     Decode.decode(localStorage.getItem("userBiAdmin"))
    //   );
    //   if (userData.user.type !== "ADMIN") {
    //     localStorage.setItem("selectedType", Encode.encode(userData.user.type));
    //     var selectedType = JSON.parse(
    //       Decode.decode(localStorage.getItem("selectedType"))
    //     );
    //     console.log("selectedType", selectedType);
    //     this.$router.push("/Dashboard");
    //   }
    // }
  },
  methods: {
    // ธุรกิจสัตว์ปีก
    // ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง
    // ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป
    aaaaa(val) {
      console.log("val", val);
      if (val == "ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง") {
        this.Livestock = true;
        this.Pet = false;
        this.Poultry = false;
      } else if (val == "ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป") {
        this.Livestock = false;
        this.Pet = true;
        this.Poultry = false;
      } else if (val == "ธุรกิจสัตว์ปีก") {
        this.Livestock = false;
        this.Pet = false;
        this.Poultry = true;
      }
      this.selected = val;
    },
    async submit() {
      const data = {
        username: this.username,
        password: this.password,
        type: this.selectedType,
        admin_type: "ADMIN",
        activeFlag: true,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/users/createAdmin`,
        data
      );
      console.log("response", response);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `สร้างผู้ใช้งานสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push("/");
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>

<style scoped>
.tab-color {
  border: 2px solid #1a347f;
  box-sizing: border-box;
  border-radius: 4px;
}
/* 
.tab-color {
  border: 5px solid #9f9f9f;
  border-radius: 55px;
} */
.page {
  width: 21cm;
  /* height: 29.7cm; */
  height: 23.6cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
@page {
  size: A4;
  margin: 0;
}
</style>