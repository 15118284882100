<template>
  <div>
    <ManageQuiz />
  </div>
</template>
<script>
import ManageQuiz from "@/components/Quiz/ManageQuiz";
export default {
  components: {
    ManageQuiz,
  },
  created() {},
};
</script>
