<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7">Quiz</div>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>

          <v-col class="text-right">
            <v-btn
              dark
              class="rounded-xl"
              style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
              @click="dialogStepFormOpen()"
            >
              <span class="text-center mx-4 fn14-weight7">
                <a-icon type="plus" class="mr-2" />
                Add New Quiz
              </span>
            </v-btn>
            <stepForm
              :dialog="dialog"
              :form.sync="form"
              :isEdit="isEdit"
              :items="itemEdit"
              @clear="clear()"
              @close="closeDialog()"
              @getAllQuiz="getAllQuiz()"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-tabs v-model="tabQuiz">
        <v-tab
          v-for="(item, index) in tabQuizItems"
          :key="index"
          @click="cellItems(item.key)"
        >
          {{ item.text }}
        </v-tab></v-tabs
      >
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title>
            <!-- <div class="fn16-weight7">{{ item.content }}</div> -->
          </v-card-title>
          <v-data-table
            v-if="tabQuiz == 0"
            :headers="headers"
            :items="items"
            :search="search"
            disable-pagination
            hide-default-footer
            class="elevation-1"
            style="font-weight: 700"
          >
            <!-- <template v-slot:header.count="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template> -->
            <template v-slot:header.upload_image="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.title="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.point="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.period="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.status="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>

            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>

            <template v-slot:[`item.upload_image`]="{ item }">
              <v-img class="ma-1" width="80px" :src="item.upload_image"></v-img>
            </template>
            <template v-slot:[`item.point`]="{ item }">
              <span>{{ item.point }}</span>
            </template>
            <template v-slot:[`item.period`]="{ item }">
              <span
                >{{ convertDate(item.period_start) }} -
                {{ convertDate(item.period_end) }}
              </span>
            </template>
            <template v-slot:[`item.completed`]="{ item }">
              <span class="fn14-weight7" style="color: green">{{
                item.completed
              }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center" class="my-2">
                <!-- <v-icon @click="Viewbanners(item)">mdi-eye</v-icon> -->
                <!-- <v-btn
                  dark
                  outlined
                  block
                  class="rounded-xl fn12-weight7 mb-2"
                  style="color: #4f4f4f"
                >
                  View
                </v-btn> -->
                <v-btn
                  dark
                  outlined
                  block
                  class="rounded-xl fn12-weight7 mb-2"
                  style="color: #4f4f4f"
                  @click="deleteKnowLedge(item)"
                >
                  Delete
                </v-btn>
                <v-btn
                  dark
                  outlined
                  block
                  class="rounded-xl fn12-weight7"
                  style="color: #4f4f4f"
                  @click="UpdateKnowledge(item)"
                >
                  Edit
                </v-btn>
              </v-row>
            </template>
            <template v-slot:[`item.download`]="{ item }">
              <v-btn
                dark
                outlined
                class="rounded-xl fn12-weight7"
                style="color: #07204b"
              >
                Download
              </v-btn>
            </template>
          </v-data-table>
          <!-- <v-data-table
            v-if="tabQuiz == 1"
            :headers="headersAnswer"
            :items="items"
            :search="search"
            disable-pagination
            hide-default-footer
            class="elevation-1"
            style="font-weight: 700"
          >
           
            <template v-slot:header.quiz.upload_image="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.quiz.title="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.point="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.name="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.updatedAt="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>

            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>

            <template v-slot:[`item.quiz.upload_image`]="{ item }">
              <v-img
                v-if="item.quiz"
                class="ma-1"
                width="80px"
                :src="item.quiz.upload_image"
              ></v-img>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <span v-if="item.user"
                >{{ item.user.firstname }} {{ item.user.lastname }}</span
              >
            </template>
            <template v-slot:[`item.point`]="{ item }">
              <span>{{ item.total }} / {{ item.point }}</span>
            </template>
            <template v-slot:[`item.updatedAt`]="{ item }">
              <span>{{ convertDateTime(item.updatedAt) }} </span>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center" class="my-2">
               
                <v-btn
                  dark
                  outlined
                  block
                  class="rounded-xl fn12-weight7 mb-2"
                  style="color: #4f4f4f"
                  @click="deleteAnswer(item)"
                >
                  Delete
                </v-btn>
              
              </v-row>
            </template>
            <template v-slot:[`item.download`]="{ item }">
              <v-btn
                dark
                outlined
                class="rounded-xl fn12-weight7"
                style="color: #07204b"
              >
                Download
              </v-btn>
            </template>
          </v-data-table> -->
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import stepForm from "@/components/Shared/stepFormQuiz";
import moment from "moment";
export default {
  components: {
    draggable,
    Loading,
    stepForm,
  },
  data() {
    return {
      tabQuiz: 0,
      tabQuizItems: [
        { text: "ManageQuiz", key: "ManageQuiz" },
        // { text: "ViewAnswers", key: "ViewQuiz" },
      ],
      dialog: false,
      items: [],
      itemEdit: {},
      isEdit: "",
      headersAnswer: [
        {
          text: "Thumbnail",
          value: "quiz.upload_image",
          align: "center",
        },
        {
          text: "Title",
          value: "quiz.title",
          align: "center",
        },
        {
          text: "Name",
          value: "name",
          align: "center",
        },
        {
          text: "Point",
          value: "point",
          align: "center",
        },
        {
          text: "Date Time",
          value: "updatedAt",
          align: "center",
        },
        { text: "Action", value: "action", align: "center", width: "100" },
      ],
      headers: [
        {
          text: "Thumbnails",
          value: "upload_image",
          width: "70",
          align: "center",
        },
        {
          text: "Question Title",
          value: "title",
          align: "center",
          width: "200",
        },
        {
          text: "Points",
          value: "point",
          align: "center",
          width: "150",
        },
        {
          text: "Period",
          value: "period",
          align: "center",
          width: "130",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          width: "130",
        },
        // {
        //   text: "Last Updated",
        //   value: "updated",
        //   align: "center",
        //   width: "150",
        // },
        { text: "Action", value: "action", align: "center", width: "100" },
        // { text: "Download", value: "download", align: "center", width: "130" },
      ],
      tab: null,
      tabItems: [
        // {
        //   tab: "Active",
        //   content: "Active Questionnaires (3)",
        //   headers: [
        //     // { text: "", value: "", align: "center", width: "50" },
        //     { text: "#", value: "count", align: "center" },
        //     { text: "Campaign Name", value: "name", width: "200" },
        //     { text: "Visits", value: "visits", align: "center", width: "20" },
        //     { text: "Participation", value: "participation", align: "center" },
        //     { text: "Completed", value: "completed", align: "center" },
        //     { text: "Incomplete", value: "incomplete", align: "center" },
        //     {
        //       text: "Last Updated",
        //       value: "updated",
        //       align: "center",
        //       width: "150",
        //     },
        //     { text: "Action", value: "action", align: "center" },
        //     { text: "Download", value: "download", align: "center" },
        //   ],
        //   items: [
        //     {
        //       count: 1,
        //       name: "User Survey",
        //       visits: "100",
        //       participation: "100 (100%)",
        //       completed: "100 (100%)",
        //       incomplete: "0 (0,00%)",
        //       updated: "09/02/2020 17:00",
        //     },
        //     {
        //       count: 2,
        //       name: "User Survey",
        //       visits: "100",
        //       participation: "100 (100%)",
        //       completed: "100 (100%)",
        //       incomplete: "0 (0,00%)",
        //       updated: "09/02/2020 17:00",
        //     },
        //   ],
        // },
        // { tab: "Draft", content: "Draft" },
        // { tab: "History", content: "History" },
      ],
      form: {
        title: "",

        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        description: "",
        upload_image: "",
        usertype: "",
        status: "",
        //  title: this.form.title,
        //         description: this.form.description,
        //         upload_image: "",
        //         start_date: "",
        //         end_date: "",
        //         question_list: this.QuestionsList,
        //         usertype: "",
        //         status: "",
      },
      // form: {
      //   name: "",
      //   date: new Date().toISOString().substr(0, 10),
      //   url: "",
      //   picture_1: "",
      //   menufrom: false,
      //   publish: false,
      // },

      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
    };
  },
  created() {
    this.getAllQuiz();
  },
  methods: {
    cellItems(val) {
      console.log(val);
      if (val == "ViewQuiz") {
        this.getAllquizAnswers();
      } else if (val == "ManageQuiz") {
        this.getAllQuiz();
      }
    },
    closeDialog() {
      this.dialog = false;
      this.itemEdit = null;
      this.form = [];
      this.isEdit = "";
    },
    dialogStepFormOpen() {
      this.clear();
      this.itemEdit = null;
      this.dialog = true;
    },
    clear() {
      this.form = {
        title: "",
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        description: "",
        upload_image: "",
        usertype: "",
        status: "",
      };
    },
    UpdateKnowledge(val) {
      // localStorage.setItem("tipsdata", Encode.encode(val));
      console.log("val", val);
      this.dialog = true;
      this.isEdit = "edit";
      this.itemEdit = val;
      // this.$router.push("EditKnowledge");
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/brandsssssss/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllquizAnswers() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/quizAnswers`
      );
      console.log("answer", response.data);
      this.items = response.data.data;
    },
    async getAllQuiz() {
      const response = await this.axios.get(`${process.env.VUE_APP_API}/quizs`);
      console.log("quiz", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        let showpoint = 0;
        for (let j in this.items[i].question_list) {
          console.log("points", this.items[i].question_list[j].point);
          showpoint += this.items[i].question_list[j].point;
          console.log("showpoint", showpoint);
        this.items[i].point = showpoint
        }
        console.log("this.items[i].point", this.items[i]);
      }
      // console.log("item", this.items);
    },
    convertDateTime(val) {
      // console.log("val", val);
      return moment(val).format("MMM Do YYYY HH:mm");
      // return moment(val).format("MM/DD/YYYY ");
    },
    convertDate(val) {
      return moment(val).format("MMM Do YYYY");
    },
    UpdateQuestionnaire(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("EditQuestionnaire");
    },
    ViewQuestionnaire(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("ViewQuestionnaire");
    },
    async deleteKnowLedge(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        // const auth = {
        //   headers: { Authorization: `Bearer ${this.user.token}` },
        // };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/quizs/` + val.id
            // auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllQuiz();
        }
      });
    },
    async deleteAnswer(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        // const auth = {
        //   headers: { Authorization: `Bearer ${this.user.token}` },
        // };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/quizAnswers/` + val.id
            // auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllquizAnswers();
        }
      });
    },
    goToCreate() {
      this.$router.push("createQuestionnaire");
    },
  },
};
</script>

<style scoped>
.active {
  /* color: rgba(255, 255, 255, 0) !important; */
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
