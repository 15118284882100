<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col cols="12">
      <div class="fn24-weight7">Admin Roles</div>
    </v-col>
    <v-col cols="4">
      <v-text-field v-model="search" outlined dense></v-text-field>
    </v-col>
    <v-spacer />
    <v-col cols="4" class="text-right">
      <v-btn
        v-if="userData.user.admin_type !== 'EDITOR'"
        dark
        class="rounded-xl px-12"
        width="171"
        height="39"
        style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
        @click="DialogNewAdmin()"
        ><v-icon>mdi-plus</v-icon>Add New Admin</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-card
        ><v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.firstname || "-" }} {{ item.lastname || "" }}</span>
          </template>
          <template v-slot:[`item.admin_type`]="{ item }">
            <v-chip small outlined
              ><span>{{ item.admin_type }}</span></v-chip
            >
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip small v-if="item.status == 'ACTIVE'" color="#EDF4EC"
              ><span style="color: #2a8c34">Active</span></v-chip
            >
            <v-chip small v-if="item.status == 'PENDING'" color="#ffe599"
              ><span style="color: orange">Pending</span></v-chip
            >
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-row justify="center">
              <v-icon @click="UpdateUser(item)">mdi-dots-horizontal</v-icon>
            </v-row>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog v-model="dialogedit" width="80%" :retain-focus="false">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-card class="pa-6">
          <v-row>
            <v-col cols="8">
              <span style="color: #1a347f; font-weight: 900; font-size: 24px">
                Edit User
              </span>
            </v-col>
            <v-col cols="4" class="text-right">
              <v-btn @click="DeleteUser(form)" color="red" text
                >Delete This User</v-btn
              >
            </v-col>
            <v-col cols="6">
              <span>Role</span>
              <v-select
                v-model="form.admin_type"
                :items="['ADMIN', 'SUPERADMIN', 'EDITOR']"
                outlined
                dense
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="6">
              <span>First Name</span>
              <v-text-field
                v-model="form.firstname"
                outlined
                dense
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <span>Last Name</span>
              <v-text-field
                v-model="form.lastname"
                outlined
                dense
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <span>Email</span>
              <v-text-field
                v-model="form.username"
                outlined
                dense
                :rules="rules.emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="12">
                  <span>Status</span>
                  <!-- <v-select
                v-model="form.activeFlag"
                :items="activeFlagitem"
                item-text="text"
                item-value="value"
                outlined
                dense
                :rules="rules.required"
              >
              </v-select> -->
                  <!-- <v-text-field
                v-model="form.activeFlag"
                outlined
                dense
                readonly
              ></v-text-field> -->
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="mt-2">
                  <v-chip v-if="form.status == 'ACTIVE'" color="#EDF4EC"
                    ><span style="color: #2a8c34">Active</span></v-chip
                  >
                  <v-chip v-if="form.status == 'PENDING'" color="#ffe599"
                    ><span style="color: orange">Pending</span></v-chip
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-btn block color="#1A347F" dark @click="submituser(form)"
                >SAVE</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogadmin" width="60vw" :retain-focus="false">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-card class="pa-6">
          <v-row>
            <v-col cols="12">
              <span style="color: #1a347f; font-weight: 900; font-size: 24px"
                >Add New Admin</span
              >
            </v-col>
            <v-col cols="12">
              <span style="color: #1a347f">
                Input the user email to send admin invitation to their email
              </span>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="6">
              <span>Role</span>
              <v-select
                v-model="admin_type"
                :items="['ADMIN', 'SUPERADMIN', 'EDITOR']"
                outlined
                dense
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="6">
              <span>First Name</span>
              <v-text-field v-model="firstname" outlined dense></v-text-field>
            </v-col>
            <v-col cols="6">
              <span>Last Name</span>
              <v-text-field v-model="lastname" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                outlined
                dense
                :rules="rules.emailRules"
                label="Email Address"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn block color="#1A347F" dark @click="invite()"
                >Send invitation</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      search: "",
      activeFlagitem: [
        { text: "Active", value: true },
        { text: "Pending", value: false },
      ],
      headersAdmin: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อ", value: "name", align: "center" },
        { text: "Email", value: "email", align: "center" },
        { text: "Roles", value: "admin_type", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],
      headersSuperAdmin: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อ", value: "name", align: "center" },
        { text: "Email", value: "email", align: "center" },
        { text: "Roles", value: "admin_type", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "Action", value: "action", align: "center" },
      ],
      items: [],
      brandName: "",
      brandDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },

      checkValidate: true,
      lazy: false,
      productOptionList: [],
      userData: [],
      headers: [],
      selectedType: "",
      dialogadmin: false,
      dialogedit: false,
      email: "",
      firstname: "",
      lastname: "",
      admin_type: "",
      status: "",
      form: {
        id: "",
        firstname: "",
        lastname: "",
        admin_type: "",
        username: "",
        status: "",
        activeFlag: false,
      },
    };
  },
  created() {
    this.getAllAdmin();
  },

  methods: {
    async invite() {
      if (this.$refs.createForm.validate(true)) {
        // ธุรกิจสัตว์ปีก
        // ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง
        // ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป
        console.log("this.selectedType", this.selectedType);
        const data = {
          type: this.selectedType,
          email: this.email,
          firstname: this.firstname,
          lastname: this.lastname,
          admin_type: this.admin_type,
          status: "PENDING",
          activeFlag: false,
        };
        // /users/newAdminUser
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/users/newAdminUser`,
          data
        );
        console.log("invite", response);

        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            title: `You invitation has been sent!`,
            text: "Please check the admin panel to see the updated admin.",
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialogadmin = false;
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    DialogNewAdmin() {
      this.dialogadmin = true;
      // /users/newAdminUser
    },
    UpdateUser(val) {
      this.dialogedit = true;
      this.form = val;
      console.log("item", val);
    },
    async submituser(val) {
      if (this.$refs.createForm.validate(true)) {
        console.log("val", val);
        // console.log("this.form", this.form);
        // const auth = {
        //   headers: {
        //     Authorization: `Bearer ${this.userData.token}`,
        //   },
        // };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/users/updateAdmin/` + val.id,
          val
          // auth
        );
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขข้อมูลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.getAllAdmin();
          this.dialogedit = false;
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async DeleteUser(val) {
      console.log("val", val);
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiAdmin"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer ${this.userData.token}`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/users/removeAdmin/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          this.getAllAdmin();
          this.dialogedit = false;
        }
      });
    },
    async getAllAdmin() {
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      console.log("usertype", this.userData.user.type);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      console.log(
        "this.userData.user.admin_type",
        this.userData.user.admin_type
      );
      if (this.userData.user.admin_type == "EDITOR") {
        this.headers = this.headersAdmin;
        // this.getAllEditor();
      }
      if (this.userData.user.admin_type == "ADMIN") {
        this.headers = this.headersAdmin;
      }
      if (this.userData.user.admin_type == "SUPERADMIN") {
        this.headers = this.headersSuperAdmin;
      }
      if (this.userData.user.type == "ADMIN") {
        this.selectedType = JSON.parse(
          Decode.decode(localStorage.getItem("selectedType"))
        );
        this.userData.user.type = this.selectedType;
      }

      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findAllAdmin?type=${this.userData.user.type}`,
        auth
      );
      console.log("getAllAdmin", response.data.data);
      this.items = response.data.data;

      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    async getAllEditor() {
      // alert(this.userData.user.type);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findAllAdmin?type=${this.userData.user.type}`,
        auth
      );
      console.log("getAllAdmin", response.data.data);
      this.items = response.data.data;

      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageUser");
    },
    async addproductOptionList() {
      this.productOptionList.push({
        weightForm: this.weightForm,
        weightTo: this.weightTo,
        weightPrice: this.weightPrice,
      });
    },
    deleteproductOptionList() {
      if (this.productOptionList.length > 0) {
        this.productOptionList.pop();
      }
    },
    async addRow1() {
      this.productPriceList.push({
        price: null,
        // description: this.listDescription,
        companyId: this.companyId,
      });
    },
    deleteRow1() {
      if (this.productPriceList.length > 0) {
        this.productPriceList.pop();
      }
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          brandName: this.brandName,
          description: this.brandDescription,
          activeFlag: this.activeFlag,
          brandImg: this.pic1,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/brands`,
          data,
          auth
        );
        console.log("createBrand", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างการจัดส่งสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageUser");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
