<template>
  <v-dialog v-model="dialog" max-width="60%" persistent>
    <v-card class="pa-4">
      <v-row>
        <v-col cols="12">
          <span style="font-size: 24px">Appointment</span>
          <v-btn text style="float: right" @click="closeaa()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <!-- {{items.user}} -->
      <!-- count: 7
createdAt: (...)
date: (...)
description: (...)
doctor: (...)
doctorId: (...)
file_upload: (...)
id: (...)
meeting_link: (...)
time: (...)
title: (...)
updatedAt: (...)
user: (...)
userId: (...) -->
      <v-row align="center" justify="center">
        <v-col cols="12" md="2">
          <span style="float: right">Doctor Name: </span>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            readonly
            v-model="items.doctor.first_name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <span style="float: right">Doctor Name: </span>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            readonly
            v-model="items.doctor.last_name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <span style="float: right">Date: </span>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            readonly
            v-model="items.date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <span style="float: right">Time: </span>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            readonly
            v-model="items.time"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <span style="float: right">Title: </span>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            readonly
            v-model="items.title"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <span style="float: right">Description: </span>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            readonly
            v-model="items.description"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <span style="float: right">Meeting URL: </span>
        </v-col>
        <v-col cols="12" md="10">
          <v-text-field
            outlined
            dense
            readonly
            v-model="items.meeting_link"
          ></v-text-field>
        </v-col>
          <v-col cols="12" md="2">
          <span style="float: right">Firstname: </span>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            readonly
            v-model="items.user.firstname"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <span style="float: right">Lastname: </span>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            outlined
            dense
            readonly
            v-model="items.user.lastname"
          ></v-text-field>
        </v-col>
        <v-img :src="items.file_upload" />
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    items: Object,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeaa() {
      this.$emit("close");
    },
  },
};
</script>