<template>
  <v-dialog v-model="dialog" max-width="900px" max-height="auto" persistent>
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        v-on="on"
        class="rounded-xl"
        style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
      >
        <span class="text-center mx-4 fn14-weight7">
          <a-icon type="plus" class="mr-2" />
          Add New Quiz
        </span>
      </v-btn>
    </template> -->
    <v-form>
      <v-row class="pa-4" style="background: white">
        <v-col cols="6">
          <div class="fn20-weight7">Create Question</div>
        </v-col>

        <v-stepper v-model="start" vertical width="100%">
          <v-stepper-step :complete="start > 1" step="1" class="fn16">
            Question Details
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row style="color: #1a347f" class="fn16">
              <v-col cols="6" class="pb-0">Title*: </v-col>
              <v-col cols="6" class="pb-0">Period*:</v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  v-model="form.title"
                  outlined
                  dense
                  placeholder="Question Title"
                />
              </v-col>
              <v-col cols="3">
                <v-menu
                  v-model="form.menufrom"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.start_date"
                      prepend-icon="mdi-calendar"
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.start_date"
                    @input="form.menufrom = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <v-menu
                  v-model="form.menuto"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.end_date"
                      prepend-icon="mdi-calendar"
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.end_date"
                    @input="form.menuto = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="pb-0">Description:</v-col>
              <v-col cols="12" class="pb-0">
                <v-textarea
                  v-model="form.description"
                  outlined
                  dense
                  rows="3"
                  placeholder="Description"
                />
              </v-col>
              <v-col cols="12" class="">
                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" class="mt-5 ma-10" id="step-3">
                    <v-img
                      v-model="form.picture_1"
                      @click="changePic1()"
                      src="@/assets/icons/upload.png"
                      class="mr-3 d-inline-block"
                      max-width="18"
                      max-height="22"
                    >
                    </v-img>
                    <span
                      style="color: #1a347f"
                      class="fn16-weight7"
                      @click="changePic1()"
                    >
                      Upload Thumbnails Image
                    </span>
                    <br />
                    <span style="color: red" class="fn14"
                      >ใช้ขนาด 500*250
                    </span>
                    <v-col
                      cols="12"
                      md="3"
                      class="
                        pl-5
                        pr-5
                        row
                        fill-height
                        align-center
                        sortable-list
                      "
                    >
                      <v-card dense light class="pa-1" v-if="showImage">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-icon small light @click="RemoveImage"
                            >mdi-close</v-icon
                          >
                        </v-card-actions>

                        <v-img
                          :src="showImage"
                          width="200px"
                          aspect-ratio="1.8"
                          contain
                        ></v-img>
                      </v-card>
                    </v-col>
                    <input
                      type="file"
                      ref="image"
                      id="picTure1"
                      accept="image/*"
                      @change="showPicture1($event)"
                      style="display: none"
                      hidden
                  /></v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="start = 2"> Continue </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="start > 2" step="2">
            Questions List
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-row
              v-for="(i, index) in question_list"
              :key="index"
              class="my-0 py-0"
              dense
            >
              <v-col cols="12">
                <span>Question #{{ index + 1 }}</span>
              </v-col>
              <v-col cols="8">
                <span>Question Title*</span>
              </v-col>
              <v-col cols="4">
                <span>Points Reward</span>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="question_list[index].title"
                  outlined
                  dense
                  placeholder="Question Title"
                  hide-details
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="question_list[index].point"
                  outlined
                  dense
                  placeholder="Type from 1-100"
                  hide-details
                />
              </v-col>
              <v-col cols="12">
                <v-row v-for="(j, index) in i.option" :key="index" dense>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="j.isAnswer"
                      class="mt-0 pt-2"
                      style="float: right"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="10" offset="0">
                    <v-text-field
                      v-model="j.choice"
                      outlined
                      dense
                      hide-details
                    >
                      <!-- placeholder="Option" -->
                      <template v-slot:label> Option {{ index + 1 }}</template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="10" offset="2">
                <v-btn text small class="mb-10" @click="addOption(index)"
                  ><v-icon>mdi-plus</v-icon>Add Option</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-btn text small class="mb-10" @click="addQuestion()"
                  ><v-icon>mdi-plus</v-icon>Add Question</v-btn
                >
              </v-col>
            </v-row>
            <v-btn color="primary" class="mr-2" @click="start = 3">
              Continue
            </v-btn>
            <v-btn @click="start = 1"> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper>
        <v-col cols="12" class="px-0 pb-0" align="center" justify="center">
          <v-btn
            :dark="start < 3 ? false : true"
            :disabled="start < 3"
            class="rounded-lg mt-2"
            :style="
              start < 3
                ? ''
                : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
            "
            block
            @click="submit()"
          >
            <span class="text-center fn14-weight7"> Save form </span>
          </v-btn>
          <v-btn
            class="rounded-lg mt-2"
            style="color: #9bbef2"
            outlined
            width="250"
            block
            @click="cancel()"
          >
            <span class="text-center fn14-weight7"> cancel </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    form: Object,
    btnName: {
      type: String,
      default: "Create New",
    },
    isEdit: {
      type: String,
      default: "",
    },
    items: Object,
  },
  data() {
    return {
      question_list: [
        {
          title: "",
          point: 0,
          // option: [],
          option: [
            { choice: "", isAnswer: false },
            { choice: "", isAnswer: false },
          ],
        },
      ],
      dialog: false,
      start: 1,
      templates: [
        {
          no: 1,
          name: "List",
        },
        {
          no: 2,
          name: "Drop-down List",
        },
        {
          no: 3,
          name: "Either-or",
        },
        {
          no: 4,
          name: "Stars",
        },
      ],

      menufrom: false,
      product: "",
      items: [],
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      idEdit: "",
      selectedType: "",
    };
  },

  // created() {
  //   this.getAllProduct();
  // },

  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  watch: {
    items: {
      handler(val) {
        if (this.isEdit === "edit") {
          this.getQuizById(val.id);
          console.log(val.id);
        }
      },
      deeps: true,
    },
  },
  methods: {
    async getQuizById(id) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/quizs/` + id
      );
      const { data } = response.data;
      console.log("responseById", data);

      // usertype: "",
      // status: "",
      this.form.title = data.title;
      this.form.start_date = data.start_date.substr(0, 10);
      this.form.end_date = data.end_date.substr(0, 10);

      this.form.description = data.description;
      this.form.upload_image = data.upload_image;
      this.idEdit = data.id;
      this.question_list = data.question_list;
      this.showImage = data.upload_image;
    },
    addOption(index) {
      console.log("index", index);
      this.question_list[index].option.push({ choice: "", isAnswer: false });
    },
    addQuestion() {
      // console.log("index", index);
      this.question_list.push({
        title: "",
        point: 0,
        option: [
          { choice: "", isAnswer: false },
          { choice: "", isAnswer: false },
        ],
      });
    },
    selectTemplate() {},
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/
          `
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
    },
    // async getAllProduct() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/findAllAdmin?brandId=` +
    //       this.brand +
    //       "&typeId=" +
    //       this.type +
    //       "&categoryId=" +
    //       this.category
    //     // 'http://localhost:8081/types/'
    //   );
    //   console.log("getAllproducts", response.data);
    //   this.items = response.data.data;
    //   for (let i in this.items) {
    //     this.items[i].count = parseInt(i) + 1;
    //     //console.log(this.list);
    //   }
    // },
    async showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;

      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(element);
        // reader.onload = () => {
        //   this.pic1 = reader.result;
        //   this.showImage = URL.createObjectURL(element);
        // };
        console.log(element);

        // var user = JSON.parse(
        //   Decode.decode(localStorage.getItem("userBiAdmin"))
        // );
        // const auth = {
        //   headers: {
        //     Authorization: `Bearer ${user.token}`,
        //   },
        // };

        // const response = await this.axios.get(
        //   `${process.env.VUE_APP_API}/banner/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   auth
        // );

        // console.log("response", response);
        // console.log("url", response.data.url);
        let formData = new FormData();
        formData.append("files", element);
        const responseUrl = await this.axios.post(
          `https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/files/uploadFormData`,
          formData
        );
        console.log("responseUrl", responseUrl.data.data);
        this.imageType = element.type;
        this.showImage = responseUrl.data.data.url;
        // /reward/upload-url-img/?contentType=jpg&image_name=TEST_IMG
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.picture_1.splice(1, 1);
    },
    cancel() {
      this.start = 1;
      this.showImage = "";
      this.question_list = [];
      this.addQuestion();
      this.$emit("close");

      if (this.isEdit !== "edit") {
        this.$emit("clear");
      }
      // this.start = 1;
      // this.dialog = false;
    },
    async submit() {
      // console.log("response", response.data.data);
      if (this.isEdit === "edit") {
        // this.form = {
        //   ...this.form,
        //   id: this.idEdit,
        // };
        const data = {
          id: this.idEdit,
          title: this.form.title,
          description: this.form.description,
          question_list: this.question_list,
          upload_image: this.showImage,
          // this.showImage = data.upload_image;
          start_date: this.form.start_date,
          end_date: this.form.end_date,
          usertype: "",
          status: "",
          // ...this.form,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/quizs/${this.idEdit}`,
          data
        );
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            iconColor: "#1a347f",
            text: `แก้ไขquizสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllQuiz");
          this.start = 1;
          this.showImage = "";
          this.$emit("clear");
          this.$emit("close");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขquizไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllQuiz");
          this.start = 1;
          this.showImage = "";
          this.$emit("clear");
          this.$emit("close");
        }
      } else {
        const data = {
          title: this.form.title,
          description: this.form.description,
          question_list: this.question_list,
          upload_image: this.showImage,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
          usertype: "",
          status: "",
          // ...this.form,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/quizs`,
          data
        );
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            iconColor: "#1a347f",
            text: `สร้างquizสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllQuiz");
          this.$router.push("ManageQuiz");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างquizไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.showImage = "";
        this.$emit("clear");
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>
.select {
  border-color: blue;
}
</style>
