<template>
  <v-app id="inspire">
    <v-main>
      <v-img src="@/assets/backgroundBi.png">
        <v-container fluid fill-height class="backgroundBi">
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4 style="text-align: -webkit-center">
              <!-- <v-row justify="center" align="center">
                <v-img
                  src="@/assets/liff-logo.png"
                  max-width="200px"
                  max-height="200px"
                />

                <span
                  style="font-weight: 800; font-size: 24px; color: white"
                  class="mt-2"
                >
                  Boehringer Ingelheim admin
                </span>
                <br />
              </v-row> -->
              <v-card
                class="elevation-12 mt-12"
                max-width="370px"
                style="text-align: -webkit-center"
              >
                <v-row align="center" justify="center">
                  <v-img
                    class="my-10"
                    src="@/assets/Group1.png"
                    max-width="200px"
                    max-height="200px"
                  />
                </v-row>
                <v-card-text>
                  <v-form
                    ref="loginfrom"
                    v-model="loginfrom"
                    :lazy-validation="lazy"
                  >
                    <v-text-field
                      placeholder="Username"
                      type="text"
                      outlined
                      dense
                      v-model="username"
                      :rules="Rules.empty"
                      @keyup.enter="login()"
                    ></v-text-field>
                    <v-text-field
                      placeholder="Password"
                      type="password"
                      outlined
                      dense
                      v-model="password"
                      :rules="Rules.empty"
                      @keyup.enter="login()"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="8">
                    <v-btn
                      style="
                        background: linear-gradient(268.1deg, #07204b, #1a347f);
                        color: white;
                      "
                      class="font-weight-light"
                      rounded
                      block
                      @click="login()"
                      >Sign in</v-btn
                    >
                  </v-col>
                  <v-col cols="8">
                    <button @click="$msal.signIn()">msal</button>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center" class="pb-6">
                  <v-btn text color="#1A347F" @click="forgotpassword()">
                    Forget Password ?</v-btn
                  >
                </v-row>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-img>
      <v-row>
        <footerPage />
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import { Encode, Decode } from "@/services";
import footerPage from "@/components/Shared/footer";
export default {
  components: {
    footerPage,
  },
  data() {
    return {
      lazy: "",
      loginfrom: "",
      Rules: {
        empty: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      username: "",
      password: "",
    };
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(localStorage, "userBiAdmin")) {
      var checklogin = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      ).name;
      if (checklogin !== "") {
        console.log(checklogin);
        this.$router.push("/ManageUser");
      }
    }
    // if (!this.$msal.isAuthenticated()) {
    //   this.$msal.signIn();
    // }
  },

  methods: {
    forgotpassword() {
      this.$router.push("/forgotpassword");
    },
    resetValidation() {
      this.$refs.loginfrom.resetValidation();
    },
    async login() {
      const login = {
        username: this.username,
        password: this.password,
      };

      // if (this.username == "admin" && this.password == "1234") {
      //   localStorage.setItem("userBiAdmin", Encode.encode(login));
      //   await this.$swal.fire({
      //     showConfirmButton: false,
      //     timer: 1000,
      //     timerProgressBar: true,
      //     icon: "success",
      //     text: "เข้าสู่ระบบเสร็จสิ้น",
      //   });
      //   await this.$router.push("/ManageUser");
      // } else {
      //   await this.$swal.fire({
      //     showConfirmButton: false,
      //     timer: 1000,
      //     timerProgressBar: true,
      //     icon: "error",
      //     text: response.data.message,
      //   });
      // }
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/users/adminLogin`,
        login
      );
      console.log("login", response.data.data);

      if (response.data.response_status === "SUCCESS") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "success",
          text: "เข้าสู่ระบบเสร็จสิ้น",
        });

        localStorage.setItem("userBiAdmin", Encode.encode(response.data.data));

        await this.$router.push("/SelectType");
      } else if (this.username == "" || this.password == "") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอก username password",
        });
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: response.data.message,
        });
      }
    },
  },
};
</script>
<style scoped>
.backgroundBi {
  /* background-color: #fff; */
  background-image: url(`@/assets/backgroundBi.png`);
  position: absolute;
}
</style>