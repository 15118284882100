<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7">User Management</div>
      </v-col>
      <v-col cols="12">
        <v-card outlined class="pa-2">
          <span>filter</span>
          <v-row dense>
            <v-col cols="2" class="pb-0">
              <v-autocomplete
                class="mb-4"
                v-model="searchData.royalty_status"
                :items="royalty_status_item"
                item-text="text"
                item-value="value"
                dense
                hide-details
                outlined
                label="Status"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="pb-0">
              <v-autocomplete
                class="mb-4"
                v-model="searchData.status"
                :items="status_item"
                item-text="text"
                item-value="value"
                dense
                hide-details
                outlined
                label="VEEVA Status"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="menufrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <span>วันที่เริ่ม<span style="color: red">*</span></span> -->
                      <v-text-field
                        v-model="searchData.dateFrom"
                        label="วันที่เริ่ม"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchData.dateFrom"
                      @input="menufrom = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="menuto"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="searchData.dateTo"
                        label="วันที่สิ้นสุด"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="searchData.dateTo"
                      @input="menuto = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="text-right">
              <v-btn
                dark
                outlined
                class="rounded-xl"
                width="171"
                height="39"
                style="color: red"
              >
                clear
              </v-btn>
              <v-btn
                dark
                class="rounded-xl ml-2"
                width="171"
                height="39"
                style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
                @click="searchUser(searchData)"
              >
                search
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-col cols="4" class="pb-0">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="6" class="text-right">
            <!-- <v-btn
              dark
              outlined
              class="rounded-xl"
              width="171"
              height="39"
              style="color: #1a347f"
            >
              Export
            </v-btn> -->
            <ExportUser :data="items" />
            <v-btn
              dark
              class="rounded-xl ml-2"
              width="171"
              height="39"
              style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
              @click="importFile()"
            >
              Import
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
            sort-by="id"
          >
            <template v-slot:header.id="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.username="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.firstname="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.lastname="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.type="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.species_type="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.vet_card_id="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.bi_current_point="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.ce_current_credit="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.royalty_status="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.status="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:[`item.id`]="{ item }">
              <span style="font-weight: 700">{{ item.id }}</span>
            </template>
            <template v-slot:[`item.firstname`]="{ item }">
              <span style="font-weight: 700">{{ item.firstname }}</span>
            </template>
            <template v-slot:[`item.username`]="{ item }">
              <span style="font-weight: 700">{{ item.username }}</span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <!-- <span style="font-weight: 700" v-if="item.type == 'vet'">{{
                item.type
              }}</span>
              <span style="font-weight: 700" v-if="item.type !== 'vet'">
              non-vet  
              </span> -->
              <span style="font-weight: 700" v-if="item.vet_card_id">
                <!-- {{ item.type }} -->ใช่
              </span>
            </template>
            <template v-slot:[`item.lastname`]="{ item }">
              <span style="font-weight: 700">{{ item.lastname }}</span>
            </template>
            <template v-slot:[`item.no`]="{ item }">
              <span style="font-weight: 700">{{ item.no }}</span>
            </template>
            <template v-slot:[`item.ce_current_credit`]="{ item }">
              <span v-if="item.ce_current_credit">{{
                item.ce_current_credit
              }}</span>
              <span v-else>-</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <ViewUser :dialog="dialog" :items="item" />
                <!-- <v-btn
                  @click="viewUser(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl"
                  style="color: #4f4f4f"
                >
                  View Details
                </v-btn> -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
      <v-dialog v-model="dialogimport" width="40%">
        <v-card class="pa-10 text-center">
          <br />
          <v-row> <h1>Import User</h1></v-row>
          <v-row>
            <input
              type="file"
              accept=".csv"
              id="file"
              ref="file"
              v-on:change="handleFileUpload()"
            />
            <v-spacer></v-spacer>
            <v-btn
              dark
              class="rounded-xl ml-2"
              width="171"
              height="39"
              style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
              v-on:click="submitFile()"
              >upload</v-btn
            >
          </v-row>
          <v-row
            ><span style="color: red">ใช้นามสกุล .csv เท่านั้น </span></v-row
          >
          <br />
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>
<script>
import ExportUser from "@/views/ExportExcel/ExportUser.vue";
import { Decode, Encode } from "@/services";
import ViewUser from "@/components/Shared/viewUser";
export default {
  components: {
    ExportUser,
    ViewUser,
  },
  data() {
    return {
      dialog: false,
      menufrom: false,
      menuto: false,
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      searchData: {
        search: "",
        royalty_status: "",
        status: "",
        dateFrom: new Date("2022-01-01").toISOString().substr(0, 10),
        dateTo: new Date().toISOString().substr(0, 10),
      },
      royalty_status_item: [
        { text: "ทั้งหมด", value: "" },
        { text: "Subscribed", value: "Subscribed" },
        { text: "Unsubscribe	", value: "Unsubscribe	" },
      ],
      status_item: [
        { text: "ทั้งหมด", value: "" },
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "INACTIVE	", value: "INACTIVE	" },
      ],
      headers: [
        { text: "ID", value: "id", align: "center", width: 70 },
        { text: "VEEVA ID", value: "username", align: "center", width: 130 },
        { text: "First Name", value: "firstname", align: "center", width: 130 },
        { text: "Last Name", value: "lastname", align: "center", width: 130 },
        { text: "สัตว์แพทย์?", value: "type", align: "center", width: 150 },
        // { text: "ประเภทธุรกิจ", value: "species_type", align: "center", width: 200 },
        {
          text: "เลขที่ใบประกอบโรคศิลป์",
          value: "vet_card_id",
          align: "center",
          width: 220,
        },
        {
          text: "BI Points",
          value: "bi_current_point",
          align: "center",
          width: 120,
        },
        {
          text: "CE Credits",
          value: "ce_current_credit",
          align: "center",
          width: 130,
        },
        { text: "Status", value: "royalty_status", align: "start", width: 120 },
        {
          text: "VEEVA status",
          value: "status",
          align: "center",
          width: 150,
        },
        // { text: "Healthexpress PRO", value: "isPro", align: "center" },
        { text: "Actions", value: "action", align: "center" },
      ],
      //       bi_current_point: (...)
      // ce_current_credit: (...)
      // count: 1
      // createdAt: (...)
      // firstname: (...)
      // id: (...)
      // lastname: (...)
      // lineid: (...)
      // vet_card_id: (...)
      // onemsgparam: (...)
      // password: (...)
      // registered_date: (...)
      // status: (...)
      // type: (...)
      // updatedAt: (...)
      // username: (...)
      items: [
        // {
        //   firstname: "DekDee",
        //   lastname: "jaidee",
        //   type: "Vet",
        //   no: "93837464",
        //   point: 100,
        //   credit: 100,
        //   status: "Subscribed",
        // },
        // {
        //   firstname: "DekDee",
        //   lastname: "jaidee",
        //   type: "Vet",
        //   no: "93837464",
        //   point: 100,
        //   credit: 100,
        //   status: "Subscribed",
        // },
        // {
        //   firstname: "DekDee",
        //   lastname: "jaidee",
        //   type: "Non Vet",
        //   no: "93837464",
        //   point: 100,
        //   credit: "",
        //   status: "Subscribed",
        // },
        // {
        //   firstname: "DekDee",
        //   lastname: "jaidee",
        //   type: "Non Vet",
        //   no: "93837464",
        //   point: 100,
        //   credit: "",
        //   status: "Unsubscribed",
        // },
        // {
        //   firstname: "DekDee",
        //   lastname: "jaidee",
        //   type: "Vet",
        //   no: "93837464",
        //   point: 100,
        //   credit: 100,
        //   status: "Unsubscribed",
        // },
      ],
      search: "",
      count: 0,
      dialogimport: false,
      file: "",
      type: "",
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    importFile() {
      this.dialogimport = true;
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      console.log("file", file);
      console.log(this.type);
    },
    async submitFile() {
      if (this.$refs.formupload.validate(true)) {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("type", this.type);
        formData.append("companyId", this.id);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        this.uploaded = true;
        const response = await axios.post(
          `${process.env.VUE_APP_API}/xxxx/formData`,
          formData,
          auth
        );
        // .then(function () {
        console.log("SUCCESS!!");
        console.log("response", response.data);
        this.uploaded = false;
        if (response.data.response_status == "SUCCESS") {
          await this.$swal
            .fire({
              text: "อัพโหลดข้อมูลสำเร็จ",
              icon: "success",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ดูข้อมูล",
              cancelButtonText: "ยกเลิก",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                // this.$router.push(this.type);
                this.dialogimport = false;
              }
            });
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "error",
            text: "อัพโหลดไฟล์ไม่สำเร็จ",
          });
        }
      }
    },
    async searchUser(val) {
      console.log("val", val);
      // dateFrom: (...)
      // dateTo: (...)
      // royalty_status: (...)
      // search: (...)
      // status: (...)

      const selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      console.log("user", user);
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users?species_type=${selectedType}&royalty_status=${val.royalty_status}&status=${val.status}&fromDate=${val.dateFrom}&toDate=${val.dateTo}`,
        auth
      );
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
      console.log("response", response);
    },
    async getAllUsers() {
      const selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      console.log("user", user);
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users?species_type=${selectedType}`,
        auth
      );
      console.log("this.items", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    viewUser(val) {
      localStorage.setItem("userdata", Encode.encode(val));
      // console.log(val);
      this.$router.push("viewUser");
    },
    async DeleteUser(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        // cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiAdmin"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/users/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllUsers();
        }
      });
    },
    goToCreate() {
      this.$router.push("GenRefCode");
    },
    tab(val) {
      if (val.key == "EXPERT") {
        this.getAllExperts();
      }
      if (val.key == "USER") {
        this.getAllUsers();
      }
    },
  },
};
</script>
