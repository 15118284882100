<template>
  <div>
    <ManageAP />
  </div>
</template>
<script>
import ManageAP from "@/components/Appointment/ManageAP";
export default {
  components: {
    ManageAP,
  },
  created() {},
};
</script>c