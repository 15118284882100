<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app color="">
        <v-list height="180" class="mt-3">
          <a-row type="flex">
            <a-col
              :span="24"
              justify="center"
              align="middle"
              class="centerdiv my-8"
            >
              <v-img
                src="../assets/liff-logo.png"
                max-height="300px"
                max-width="180px"
              />
              <!-- <v-img
                src="https://health-express-web.yuzudigital.com/assets/images/bottom_icon.png"
                max-height="300px"
                max-width="150px"
              ></v-img> -->
            </a-col>
            <a-col class="px-4 text-left">
              <div class="fn16 d-block" style="display: block">
                <!-- Kittiluck Jaidee -->
                {{ userData.user.firstname }} {{ userData.user.lastname }}
              </div>
              <div class="fn12-weight7" style="color: #1a347f">
                {{ selectedType }}
              </div>
            </a-col>
            <!-- <a-col :span="24" class="centerdiv">
              <p style="font-weight: 400; font-size: 18px">{{ username }}</p>
            </a-col> -->
          </a-row>
        </v-list>
        <v-divider></v-divider>
        <!-- <a-menu mode="vertical">
          <a-menu-item
            v-for="item in Navigator"
            :key="item.key"
            @click="ChangePage(item)"
          >
            <a-icon :type="item.icon" />
            <span class="pl-5">{{ item.text }}</span>
          </a-menu-item>
        </a-menu> -->
        <a-menu mode="inline" @click="handleClick">
          <a-sub-menu
            @titleClick="titleClick"
            v-for="item in Navigator"
            :key="item.key"
            style="color: #1a347f"
          >
            <span slot="title">
              <span>{{ item.headerName }}</span>
            </span>
            <a-menu-item
              v-for="menuList in item.menuItem"
              :key="menuList.key"
              @click="ChangePage(menuList)"
              style="color: #1a347f"
            >
              <a-icon :type="menuList.icon" />
              {{ menuList.text }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </v-navigation-drawer>
      <!-- <v-app-bar app color="">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-img
          src="https://health-express-web.yuzudigital.com/img/logo_home.8a04b7b4.png"
        ></v-img>

        <v-spacer></v-spacer>
        <v-btn color="white" text @click="Logout()"
          >ออกจากระบบ<a-icon type="import" class="ml-2"
        /></v-btn>
      </v-app-bar> -->
      <v-main class="div_overflow">
        <!-- <v-container grid-list-xs> -->
        <div class="pa-4">
          <router-view></router-view>
        </div>
        <!-- </v-container> -->
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      drawer: true,
      Header: "",
      // picture: "",
      username: "",
      NavigatorDefault: [
        // {
        //   headerName: "Dashboard",
        //   menuItem: [
        //     {
        //       key: "menu0",
        //       text: "แดชบอร์ด",
        //       path: "/Dashboard",
        //       name: "Dashboard",
        //       icon: "dashboard",
        //     },
        //   ],
        // },
        // {
        //   headerName: "User Management",
        //   menuItem: [
        //     {
        //       key: "user0",
        //       text: "จัดการข้อมูลผู้ใช้งาน",
        //       path: "/ManageOrder",
        //       name: "ManageOrder",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        // {
        //   headerName: "รายงาน",
        //   menuItem: [
        //     {
        //       key: "report1",
        //       text: "ยอดขายรวม ",
        //       path: "/report1",
        //       name: "report1",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        {
          headerName: "จัดการข้อมูล",
          menuItem: [
            {
              key: "menu0",
              text: "Dashboard",
              path: "/Dashboard",
              name: "Dashboard",
              icon: "dashboard",
            },
            {
              key: "user0",
              text: "User Management",
              path: "/ManageUser",
              name: "ManageUser",
              icon: "team",
            },
            {
              key: "menu1",
              text: "Banner",
              path: "/Managebanners",
              name: "Managebanners",
              icon: "picture",
            },
            // {
            //   key: "menu12",
            //   text: "Online Consultant",
            //   path: "/ManageOC",
            //   name: "ManageOC",
            //   icon: "audit",
            // },
            // {
            //   key: "menu13",
            //   text: "Appointment",
            //   path: "/ManageAP",
            //   name: "ManageAP",
            //   icon: "calendar",
            // },
            // {
            //   key: "menu2",
            //   text: "Rich Menu",
            //   path: "/ManageMenu",
            //   name: "ManageMenu",
            //   icon: "unordered-list",
            // },
            // {
            //   key: "menu3",
            //   text: "Questionnaire",
            //   path: "/ManageQuestionnaire",
            //   name: "ManageQuestionnaire",
            //   icon: "bulb",
            // },
            // {
            //   key: "menu4",
            //   text: "Campaign (Broadcast)",
            //   path: "/ManageCampaign",
            //   name: "ManageCampaign",
            //   icon: "sync",
            // },
            {
              key: "menu5",
              text: "Rewards",
              path: "/ManageRewards",
              name: "ManageRewards",
              icon: "trophy",
            },
            {
              key: "menu11",
              text: "Redemption",
              path: "/ManageRedemption",
              name: "ManageRedemption",
              icon: "unordered-list",
            },
            {
              key: "menu6",
              text: "Knowledge Hub",
              path: "/ManageKnowledge",
              name: "ManageKnowledge",
              icon: "switcher",
            },
            // {
            //   key: "menu10",
            //   text: "Digital Assets Hub",
            //   path: "/ManageDigital",
            //   name: "ManageDigital",
            //   icon: "read",
            // },
            // {
            //   key: "menu7",
            //   text: "Auto Response",
            // path: "ManageCoupon",
            // name: "ManageCoupon",
            //   icon: "message",
            // },
            // {
            //   key: "menu8",
            //   text: "E-Learning",
            //   path: "ManageELearning",
            //   name: "ManageELearning",
            //   icon: "read",
            // },
            // {
            //   key: "menu9",
            //   text: "Loyalty Hub",
            //   path: "ManageLoyalty",
            //   name: "ManageLoyalty",
            //   icon: "trophy",
            // },
          ],
        },
        {
          headerName: "Settings",
          menuItem: [
            {
              key: "setting",
              text: "Admin Roles ",
              path: "/setting",
              name: "setting",
              icon: "setting",
            },

            {
              key: "logout",
              text: "Logout ",
              path: "/",
              name: "logout",
              icon: "logout",
            },
          ],
        },
      ],
      NavigatorAdmin: [
        // {
        //   headerName: "Dashboard",
        //   menuItem: [
        //     {
        //       key: "menu0",
        //       text: "แดชบอร์ด",
        //       path: "/Dashboard",
        //       name: "Dashboard",
        //       icon: "dashboard",
        //     },
        //   ],
        // },
        // {
        //   headerName: "User Management",
        //   menuItem: [
        //     {
        //       key: "user0",
        //       text: "จัดการข้อมูลผู้ใช้งาน",
        //       path: "/ManageOrder",
        //       name: "ManageOrder",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        // {
        //   headerName: "รายงาน",
        //   menuItem: [
        //     {
        //       key: "report1",
        //       text: "ยอดขายรวม ",
        //       path: "/report1",
        //       name: "report1",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        {
          headerName: "จัดการข้อมูล",
          menuItem: [
            {
              key: "menu0",
              text: "Dashboard",
              path: "/Dashboard",
              name: "Dashboard",
              icon: "dashboard",
            },
            {
              key: "user0",
              text: "User Management",
              path: "/ManageUser",
              name: "ManageUser",
              icon: "team",
            },
            {
              key: "menu1",
              text: "Banner",
              path: "/Managebanners",
              name: "Managebanners",
              icon: "picture",
            },
            // {
            //   key: "menu12",
            //   text: "Online Consultant",
            //   path: "/ManageOC",
            //   name: "ManageOC",
            //   icon: "audit",
            // },
            // {
            //   key: "menu13",
            //   text: "Appointment",
            //   path: "/ManageAP",
            //   name: "ManageAP",
            //   icon: "calendar",
            // },
            // {
            //   key: "menu2",
            //   text: "Rich Menu",
            //   path: "/ManageMenu",
            //   name: "ManageMenu",
            //   icon: "unordered-list",
            // },
            // {
            //   key: "menu3",
            //   text: "Questionnaire",
            //   path: "/ManageQuestionnaire",
            //   name: "ManageQuestionnaire",
            //   icon: "bulb",
            // },
            // {
            //   key: "menu4",
            //   text: "Campaign (Broadcast)",
            //   path: "/ManageCampaign",
            //   name: "ManageCampaign",
            //   icon: "sync",
            // },
            {
              key: "menu5",
              text: "Rewards",
              path: "/ManageRewards",
              name: "ManageRewards",
              icon: "trophy",
            },
            {
              key: "menu11",
              text: "Redemption",
              path: "/ManageRedemption",
              name: "ManageRedemption",
              icon: "unordered-list",
            },
            {
              key: "menu6",
              text: "Knowledge Hub",
              path: "/ManageKnowledge",
              name: "ManageKnowledge",
              icon: "switcher",
            },
            // {
            //   key: "menu10",
            //   text: "Digital Assets Hub",
            //   path: "/ManageDigital",
            //   name: "ManageDigital",
            //   icon: "read",
            // },
            // {
            //   key: "menu7",
            //   text: "Auto Response",
            // path: "ManageCoupon",
            // name: "ManageCoupon",
            //   icon: "message",
            // },
            // {
            //   key: "menu8",
            //   text: "E-Learning",
            //   path: "ManageELearning",
            //   name: "ManageELearning",
            //   icon: "read",
            // },
            // {
            //   key: "menu9",
            //   text: "Loyalty Hub",
            //   path: "ManageLoyalty",
            //   name: "ManageLoyalty",
            //   icon: "trophy",
            // },
          ],
        },
        {
          headerName: "Settings",
          menuItem: [
            {
              key: "setting",
              text: "Admin Roles ",
              path: "/setting",
              name: "setting",
              icon: "setting",
            },
            {
              key: "SelectType",
              text: "SelectType ",
              path: "/SelectType",
              name: "SelectType",
              icon: "switcher",
            },
            {
              key: "logout",
              text: "Logout ",
              path: "/",
              name: "logout",
              icon: "logout",
            },
          ],
        },
      ],

      Navigator: [],

      NavigatorPetDefault: [
        // {
        //   headerName: "Dashboard",
        //   menuItem: [
        //     {
        //       key: "menu0",
        //       text: "แดชบอร์ด",
        //       path: "/Dashboard",
        //       name: "Dashboard",
        //       icon: "dashboard",
        //     },
        //   ],
        // },
        // {
        //   headerName: "User Management",
        //   menuItem: [
        //     {
        //       key: "user0",
        //       text: "จัดการข้อมูลผู้ใช้งาน",
        //       path: "/ManageOrder",
        //       name: "ManageOrder",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        // {
        //   headerName: "รายงาน",
        //   menuItem: [
        //     {
        //       key: "report1",
        //       text: "ยอดขายรวม ",
        //       path: "/report1",
        //       name: "report1",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        {
          headerName: "จัดการข้อมูล",
          menuItem: [
            {
              key: "menu0",
              text: "Dashboard",
              path: "/Dashboard",
              name: "Dashboard",
              icon: "dashboard",
            },
            {
              key: "user0",
              text: "User Management",
              path: "/ManageUser",
              name: "ManageUser",
              icon: "team",
            },
            {
              key: "menu1",
              text: "Banner",
              path: "/Managebanners",
              name: "Managebanners",
              icon: "picture",
            },
            // {
            //   key: "menu12",
            //   text: "Online Consultant",
            //   path: "/ManageOC",
            //   name: "ManageOC",
            //   icon: "audit",
            // },
            // {
            //   key: "menu13",
            //   text: "Appointment",
            //   path: "/ManageAP",
            //   name: "ManageAP",
            //   icon: "calendar",
            // },
            // {
            //   key: "menu2",
            //   text: "Rich Menu",
            //   path: "/ManageMenu",
            //   name: "ManageMenu",
            //   icon: "unordered-list",
            // },
            // {
            //   key: "menu3",
            //   text: "Questionnaire",
            //   path: "/ManageQuestionnaire",
            //   name: "ManageQuestionnaire",
            //   icon: "bulb",
            // },
            // {
            //   key: "menu4",
            //   text: "Campaign (Broadcast)",
            //   path: "/ManageCampaign",
            //   name: "ManageCampaign",
            //   icon: "sync",
            // },
            {
              key: "menu5",
              text: "Rewards",
              path: "/ManageRewards",
              name: "ManageRewards",
              icon: "trophy",
            },
            {
              key: "menu11",
              text: "Redemption",
              path: "/ManageRedemption",
              name: "ManageRedemption",
              icon: "unordered-list",
            },
            {
              key: "menu6",
              text: "Knowledge Hub",
              path: "/ManageKnowledge",
              name: "ManageKnowledge",
              icon: "switcher",
            },
            {
              key: "menu10",
              text: "Digital Assets Hub",
              path: "/ManageDigital",
              name: "ManageDigital",
              icon: "read",
            },
            // {
            //   key: "menu7",
            //   text: "Auto Response",
            // path: "ManageCoupon",
            // name: "ManageCoupon",
            //   icon: "message",
            // },
            // {
            //   key: "menu8",
            //   text: "E-Learning",
            //   path: "ManageELearning",
            //   name: "ManageELearning",
            //   icon: "read",
            // },
            // {
            //   key: "menu9",
            //   text: "Loyalty Hub",
            //   path: "ManageLoyalty",
            //   name: "ManageLoyalty",
            //   icon: "trophy",
            // },
          ],
        },
        {
          headerName: "Settings",
          menuItem: [
            {
              key: "setting",
              text: "Admin Roles ",
              path: "/setting",
              name: "setting",
              icon: "setting",
            },

            {
              key: "logout",
              text: "Logout ",
              path: "/",
              name: "logout",
              icon: "logout",
            },
          ],
        },
      ],
      NavigatorPetAdmin: [
        // {
        //   headerName: "Dashboard",
        //   menuItem: [
        //     {
        //       key: "menu0",
        //       text: "แดชบอร์ด",
        //       path: "/Dashboard",
        //       name: "Dashboard",
        //       icon: "dashboard",
        //     },
        //   ],
        // },
        // {
        //   headerName: "User Management",
        //   menuItem: [
        //     {
        //       key: "user0",
        //       text: "จัดการข้อมูลผู้ใช้งาน",
        //       path: "/ManageOrder",
        //       name: "ManageOrder",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        // {
        //   headerName: "รายงาน",
        //   menuItem: [
        //     {
        //       key: "report1",
        //       text: "ยอดขายรวม ",
        //       path: "/report1",
        //       name: "report1",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        {
          headerName: "จัดการข้อมูล",
          menuItem: [
            {
              key: "menu0",
              text: "Dashboard",
              path: "/Dashboard",
              name: "Dashboard",
              icon: "dashboard",
            },
            {
              key: "user0",
              text: "User Management",
              path: "/ManageUser",
              name: "ManageUser",
              icon: "team",
            },
            {
              key: "menu1",
              text: "Banner",
              path: "/Managebanners",
              name: "Managebanners",
              icon: "picture",
            },
            // {
            //   key: "menu12",
            //   text: "Online Consultant",
            //   path: "/ManageOC",
            //   name: "ManageOC",
            //   icon: "audit",
            // },
            // {
            //   key: "menu13",
            //   text: "Appointment",
            //   path: "/ManageAP",
            //   name: "ManageAP",
            //   icon: "calendar",
            // },
            {
              key: "menu14",
              text: "Quiz",
              path: "/ManageQuiz",
              name: "Quiz",
              icon: "question-circle",
            },
            // {
            //   key: "menu2",
            //   text: "Rich Menu",
            //   path: "/ManageMenu",
            //   name: "ManageMenu",
            //   icon: "unordered-list",
            // },
            // {
            //   key: "menu3",
            //   text: "Questionnaire",
            //   path: "/ManageQuestionnaire",
            //   name: "ManageQuestionnaire",
            //   icon: "bulb",
            // },
            // {
            //   key: "menu4",
            //   text: "Campaign (Broadcast)",
            //   path: "/ManageCampaign",
            //   name: "ManageCampaign",
            //   icon: "sync",
            // },
            {
              key: "menu5",
              text: "Rewards",
              path: "/ManageRewards",
              name: "ManageRewards",
              icon: "trophy",
            },
            {
              key: "menu11",
              text: "Redemption",
              path: "/ManageRedemption",
              name: "ManageRedemption",
              icon: "unordered-list",
            },
            {
              key: "menu6",
              text: "Knowledge Hub",
              path: "/ManageKnowledge",
              name: "ManageKnowledge",
              icon: "switcher",
            },
            {
              key: "menu10",
              text: "Digital Assets Hub",
              path: "/ManageDigital",
              name: "ManageDigital",
              icon: "read",
            },
            // {
            //   key: "menu7",
            //   text: "Auto Response",
            // path: "ManageCoupon",
            // name: "ManageCoupon",
            //   icon: "message",
            // },
            // {
            //   key: "menu8",
            //   text: "E-Learning",
            //   path: "ManageELearning",
            //   name: "ManageELearning",
            //   icon: "read",
            // },
            // {
            //   key: "menu9",
            //   text: "Loyalty Hub",
            //   path: "ManageLoyalty",
            //   name: "ManageLoyalty",
            //   icon: "trophy",
            // },
          ],
        },
        {
          headerName: "Settings",
          menuItem: [
            {
              key: "setting",
              text: "Admin Roles ",
              path: "/setting",
              name: "setting",
              icon: "setting",
            },
            {
              key: "SelectType",
              text: "SelectType ",
              path: "/SelectType",
              name: "SelectType",
              icon: "switcher",
            },
            {
              key: "logout",
              text: "Logout ",
              path: "/",
              name: "logout",
              icon: "logout",
            },
          ],
        },
      ],
      NavigatorPetEditor: [
        {
          headerName: "จัดการข้อมูล",
          menuItem: [
            {
              key: "menu0",
              text: "Dashboard",
              path: "/Dashboard",
              name: "Dashboard",
              icon: "dashboard",
            },
            {
              key: "user0",
              text: "User Management",
              path: "/ManageUser",
              name: "ManageUser",
              icon: "team",
            },
            {
              key: "menu1",
              text: "Banner",
              path: "/Managebanners",
              name: "Managebanners",
              icon: "picture",
            },
            // {
            //   key: "menu12",
            //   text: "Online Consultant",
            //   path: "/ManageOC",
            //   name: "ManageOC",
            //   icon: "audit",
            // },
            // {
            //   key: "menu13",
            //   text: "Appointment",
            //   path: "/ManageAP",
            //   name: "ManageAP",
            //   icon: "calendar",
            // },
            {
              key: "menu14",
              text: "Quiz",
              path: "/ManageQuiz",
              name: "Quiz",
              icon: "question-circle",
            },
            {
              key: "menu5",
              text: "Rewards",
              path: "/ManageRewards",
              name: "ManageRewards",
              icon: "trophy",
            },
            {
              key: "menu11",
              text: "Redemption",
              path: "/ManageRedemption",
              name: "ManageRedemption",
              icon: "unordered-list",
            },
            // {
            //   key: "menu14",
            //   text: "Quiz",
            //   path: "/ManageQuiz",
            //   name: "Quiz",
            //   icon: "question-circle",
            // },
            {
              key: "menu6",
              text: "Knowledge Hub",
              path: "/ManageKnowledge",
              name: "ManageKnowledge",
              icon: "switcher",
            },
            {
              key: "menu10",
              text: "Digital Assets Hub",
              path: "/ManageDigital",
              name: "ManageDigital",
              icon: "read",
            },
          ],
        },
        {
          headerName: "Settings",
          menuItem: [
            {
              key: "setting",
              text: "Admin Roles ",
              path: "/setting",
              name: "setting",
              icon: "setting",
            },
            {
              key: "logout",
              text: "Logout ",
              path: "/",
              name: "logout",
              icon: "logout",
            },
          ],
        },
      ],
      NavigatorPoultryAdmin: [
        // {
        //   headerName: "Dashboard",
        //   menuItem: [
        //     {
        //       key: "menu0",
        //       text: "แดชบอร์ด",
        //       path: "/Dashboard",
        //       name: "Dashboard",
        //       icon: "dashboard",
        //     },
        //   ],
        // },
        // {
        //   headerName: "User Management",
        //   menuItem: [
        //     {
        //       key: "user0",
        //       text: "จัดการข้อมูลผู้ใช้งาน",
        //       path: "/ManageOrder",
        //       name: "ManageOrder",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        // {
        //   headerName: "รายงาน",
        //   menuItem: [
        //     {
        //       key: "report1",
        //       text: "ยอดขายรวม ",
        //       path: "/report1",
        //       name: "report1",
        //       icon: "shopping-cart",
        //     },
        //   ],
        // },
        {
          headerName: "จัดการข้อมูล",
          menuItem: [
            {
              key: "menu0",
              text: "Dashboard",
              path: "/Dashboard",
              name: "Dashboard",
              icon: "dashboard",
            },
            {
              key: "user0",
              text: "User Management",
              path: "/ManageUser",
              name: "ManageUser",
              icon: "team",
            },
            {
              key: "menu1",
              text: "Banner",
              path: "/Managebanners",
              name: "Managebanners",
              icon: "picture",
            },
            {
              key: "menu12",
              text: "Online Consultant",
              path: "/ManageOC",
              name: "ManageOC",
              icon: "audit",
            },
            {
              key: "menu13",
              text: "Appointment",
              path: "/ManageAP",
              name: "ManageAP",
              icon: "calendar",
            },
            {
              key: "menu14",
              text: "Quiz",
              path: "/ManageQuiz",
              name: "Quiz",
              icon: "question-circle",
            },
            // {
            //   key: "menu2",
            //   text: "Rich Menu",
            //   path: "/ManageMenu",
            //   name: "ManageMenu",
            //   icon: "unordered-list",
            // },
            {
              key: "menu3",
              text: "Questionnaire",
              path: "/ManageQuestionnaire",
              name: "ManageQuestionnaire",
              icon: "bulb",
            },
            // {
            //   key: "menu4",
            //   text: "Campaign (Broadcast)",
            //   path: "/ManageCampaign",
            //   name: "ManageCampaign",
            //   icon: "sync",
            // },
            {
              key: "menu5",
              text: "Rewards",
              path: "/ManageRewards",
              name: "ManageRewards",
              icon: "trophy",
            },
            {
              key: "menu11",
              text: "Redemption",
              path: "/ManageRedemption",
              name: "ManageRedemption",
              icon: "unordered-list",
            },
            {
              key: "menu6",
              text: "Knowledge Hub",
              path: "/ManageKnowledge",
              name: "ManageKnowledge",
              icon: "switcher",
            },
            {
              key: "menu10",
              text: "Digital Assets Hub",
              path: "/ManageDigital",
              name: "ManageDigital",
              icon: "read",
            },
            // {
            //   key: "menu7",
            //   text: "Auto Response",
            // path: "ManageCoupon",
            // name: "ManageCoupon",
            //   icon: "message",
            // },
            // {
            //   key: "menu8",
            //   text: "E-Learning",
            //   path: "ManageELearning",
            //   name: "ManageELearning",
            //   icon: "read",
            // },
            // {
            //   key: "menu9",
            //   text: "Loyalty Hub",
            //   path: "ManageLoyalty",
            //   name: "ManageLoyalty",
            //   icon: "trophy",
            // },
          ],
        },
        {
          headerName: "Settings",
          menuItem: [
            {
              key: "setting",
              text: "Admin Roles ",
              path: "/setting",
              name: "setting",
              icon: "setting",
            },
            {
              key: "SelectType",
              text: "SelectType ",
              path: "/SelectType",
              name: "SelectType",
              icon: "switcher",
            },
            {
              key: "logout",
              text: "Logout ",
              path: "/",
              name: "logout",
              icon: "logout",
            },
          ],
        },
      ],
      selectedType: "",
      userData: [],
    };
  },
  created() {
    this.selectedType = JSON.parse(
      Decode.decode(localStorage.getItem("selectedType"))
    );
    var checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("userBiAdmin"))
    );
    console.log("user", checkuser);
    console.log("selectedType", this.selectedType);
    this.userData = checkuser;

    if (this.selectedType == "ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป") {
      if (checkuser.user.type == "ADMIN") {
        this.Navigator = this.NavigatorPetAdmin;
      } else if (checkuser.user.type == "EDITOR") {
        this.Navigator = this.NavigatorPetEditor;
      } else {
        this.Navigator = this.NavigatorPetDefault;
      }
    } else if (this.selectedType == "ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง") {
      if (checkuser.user.type == "ADMIN") {
        this.Navigator = this.NavigatorPoultryAdmin;
      }
    } else if (this.selectedType == "ธุรกิจสัตว์ปีก") {
      if (checkuser.user.type == "ADMIN") {
        this.Navigator = this.NavigatorAdmin;
        // } else if (checkuser.user.type == "EDITOR") {
        //   this.Navigator = this.NavigatorPetEditor;
      } else {
        this.Navigator = this.NavigatorDefault;
      }
    }

    this.CheckHeader();
  },
  methods: {
    openKeys(val) {
      console.log("openKeys", val);
    },
    handleClick(e) {
      console.log("click", e);
    },
    titleClick(e) {
      console.log("titleClick", e);
    },
    async ChangePage(val) {
      if (val.name == "logout") {
        this.Logout();
      } else if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      this.Header = val;
    },
    Logout() {
      localStorage.removeItem("userBiAdmin");
      localStorage.removeItem("userHealthAdmin");
      localStorage.removeItem("selectedType");
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  /* background-color: rgb(235, 240, 248); */
  overflow: auto;
  width: 100%;
  height: 94vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
</style>
