<template>
  <div>
    <ManageOC />
  </div>
</template>
<script>
import ManageOC from "@/components/OnlineConsultant/ManageOC";
export default {
  components: {
    ManageOC,
  },
  created() {},
};
</script>c