var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',{staticClass:"pa-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"fn24-weight7"},[_vm._v("Rewards")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mr-4",attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"rounded-xl",staticStyle:{"background":"linear-gradient(268.1deg, #07204b, #1a347f)"},attrs:{"dark":""},on:{"click":function($event){return _vm.dialogStepFormOpen()}}},[_c('span',{staticClass:"text-center mx-4 fn14-weight7"},[_c('a-icon',{staticClass:"mr-2",attrs:{"type":"plus"}}),_vm._v(" Add New Reward ")],1)]),_c('StepForm',{attrs:{"dialogStepForm":_vm.dialogStepForm,"form":_vm.form1,"categories":_vm.categoryRewards,"isEdit":_vm.isEdit,"items":_vm.itemEdit},on:{"update:form":function($event){_vm.form1=$event},"getAllReward":_vm.getAllReward,"clear":_vm.clear,"close":_vm.closeDialog}})],1),_c('v-col',{attrs:{"cols":""}},[_c('v-btn',{staticClass:"rounded-xl",attrs:{"dark":"","color":"blue","outlined":""},on:{"click":function($event){_vm.dialogmanagecate = true}}},[_c('span',{staticClass:"text-center mx-4 fn14-weight7"},[_c('a-icon',{staticClass:"mr-2",attrs:{"type":"plus"}}),_vm._v(" Manage Reward Category ")],1)])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":10},scopedSlots:_vm._u([{key:"header.reward_name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.rewardImg",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.reward_category.category_name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.point",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.redeem",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.stock",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.action",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.reward_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fn14-weight7"},[_vm._v(_vm._s(item.reward_name))]),_c('div',[_vm._v(_vm._s(_vm.convertDate(item.createdAt)))])]}},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fn14-weight7",staticStyle:{"color":"#384e85"}},[_vm._v(" "+_vm._s(item.points)+" ")])]}},{key:"item.redeem",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fn14-weight7",staticStyle:{"color":"#384e85"}},[_vm._v(" "+_vm._s(item.redeem)+" ")])]}},{key:"item.rewardImg",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.rewardImg,"width":"85"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"#1A347F"},on:{"change":function($event){return _vm.Status(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-xl",staticStyle:{"color":"#4f4f4f","width":"100px"},attrs:{"dark":"","outlined":"","small":""},on:{"click":function($event){return _vm.UpdateRewards(item)}}},[_vm._v(" Edit ")]),_c('v-btn',{staticClass:"rounded-xl",staticStyle:{"color":"#4f4f4f","width":"100px"},attrs:{"dark":"","outlined":"","small":""},on:{"click":function($event){return _vm.DeleteRewards(item)}}},[_vm._v(" Delete ")])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"80vw","retain-focus":false},model:{value:(_vm.dialogmanagecate),callback:function ($$v) {_vm.dialogmanagecate=$$v},expression:"dialogmanagecate"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"fn24-weight7"},[_vm._v("Manage Reward Category")])]),_c('v-col',{staticClass:"text-right"},[_c('StepFormCategory',{attrs:{"form":_vm.form},on:{"update:form":function($event){_vm.form=$event},"getAllRewardCategory":_vm.getAllRewardCategory,"clear":_vm.clear}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headercate,"items":_vm.categoryRewards,"items-per-page":10},scopedSlots:_vm._u([{key:"header.category_name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"header.action",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"fn14-weight7 black--text"},[_vm._v(_vm._s(header.text))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-xl",staticStyle:{"color":"#4f4f4f","width":"100px"},attrs:{"dark":"","outlined":"","small":""},on:{"click":function($event){return _vm.opendialogedit(item)}}},[_vm._v(" Edit ")]),_c('v-btn',{staticClass:"rounded-xl",staticStyle:{"color":"#4f4f4f","width":"100px"},attrs:{"dark":"","outlined":"","small":""},on:{"click":function($event){return _vm.DeleteRewardsCate(item)}}},[_vm._v(" Delete ")])]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"900px","retain-focus":false},model:{value:(_vm.dialogeditrewardcate),callback:function ($$v) {_vm.dialogeditrewardcate=$$v},expression:"dialogeditrewardcate"}},[_c('v-form',[_c('v-row',{staticClass:"pa-4",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"8"}},[_c('div',{staticClass:"fn20-weight7"},[_vm._v("Edit Reward Category")])]),_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","placeholder":"Category Name*"},model:{value:(_vm.form.category_name),callback:function ($$v) {_vm.$set(_vm.form, "category_name", $$v)},expression:"form.category_name"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-btn',{staticClass:"rounded-xl mr-2",staticStyle:{"color":"#9bbef2"},attrs:{"outlined":"","width":"250"},on:{"click":function($event){_vm.dialogeditrewardcate = false}}},[_c('span',{staticClass:"text-center fn14-weight7"},[_vm._v(" cancel ")])]),_c('v-btn',{staticClass:"rounded-xl",style:(_vm.form.category_name === ''
                ? ''
                : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'),attrs:{"dark":_vm.form.category_name === '' ? false : true,"disabled":_vm.form.category_name === '',"width":"250"},on:{"click":function($event){return _vm.submiteditcate()}}},[_c('span',{staticClass:"text-center fn14-weight7"},[_vm._v(" Edit ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }