<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7">Online Consulting</div>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn
              dark
              class="rounded-xl"
              style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
              @click="dialogStepFormOpen()"
            >
              <span class="text-center mx-4 fn14-weight7">
                <a-icon type="plus" class="mr-2" />
                New Consultant
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="headersbanners"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:header.status="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.name="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.image_profile="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.status="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.date="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.time="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:[`item.banner_title`]="{ item }">
              <span style="font-weight: 600">{{ item.banner_title }}</span>
            </template>
            <template v-slot:[`item.image_profile`]="{ item }">
              <v-avatar size="50" class="ma-1">
                <v-img :src="item.image_profile" class="mx-auto ma-2"
              /></v-avatar>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-row justify="center" class="mt-2">
                <v-select
                  v-model="item.status"
                  :items="statusItems"
                  outlined
                  dense
                  @change="Status(item)"
                >
                </v-select>
                <!-- <v-switch
                  v-if="item.status == true"
                  inset
                  color="#1A347F"
                  v-model="item.status"
                  @change="Status(item)"
                />
                <v-switch
                  v-if="item.status == false"
                  inset
                  color="#1A347F"
                  v-model="item.status"
                  @change="Status(item)"
                /> -->
              </v-row>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span>{{ item.first_name }} {{ item.last_name }} </span>
            </template>
            <!-- <template v-slot:[`item.period`]="{ item }">
              <span
                >{{ convertDate(item.period_start) }} -
                {{ convertDate(item.period_end) }}
              </span>
            </template> -->
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="Viewbanners(item)">mdi-eye</v-icon> -->
                <v-btn
                  @click="Updatebanners(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl mr-1"
                  style="color: #4f4f4f"
                >
                  Edit
                </v-btn>
                <v-btn
                  @click="UpdateTimeSlot(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl mr-1"
                  style="color: #4f4f4f"
                >
                  Time Slot
                </v-btn>
                <v-btn
                  @click="Deletebanners(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl mr-1"
                  style="color: #4f4f4f"
                >
                  Delete
                </v-btn>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- <DialogTimeSlot
      :form="form"
      :dialogtimeslot="dialogtimeslot"
      :itemTimeSlotEdit="itemTimeSlotEdit"
      @cleardialogtimeslot="cleardialogtimeslot"
      @closedialogtimeslot="closedialogtimeslot"
    /> -->
    <NewDoctor
      :dialogNewDoctor="dialogNewDoctor"
      :form="form"
      :isEdit="isEdit"
      :items="itemEdit"
      @getAllbanners="getAllbanners"
      @clearDialog="clearDialog"
      @closeDialog="closeDialog"
    />
    <v-row>
      <footerPage />
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
import moment from "moment";
import footerPage from "@/components/Shared/footer";
import NewDoctor from "@/components/Shared/NewDoctor";
// import DialogTimeSlot from "@/components/Shared/DialogTimeSlot";
export default {
  components: {
    footerPage,
    NewDoctor,
    // DialogTimeSlot,
  },
  data() {
    return {
      dialogNewDoctor: false,
      dialogtimeslot: false,
      isEdit: "",
      itemEdit: {},
      currentDate: moment().format("YYYY-MM-DD"),

      headersbanners: [
        { text: "Photo", value: "image_profile", align: "center" },
        { text: "Name", value: "name", align: "center" },
        { text: "Date Available", value: "date", align: "center" },
        { text: "Time Available", value: "time", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "Action", value: "action", align: "center" },
      ],
      items: [],
      statusItems: ["Available", "Not Available"],
      search: "",
      count: 0,
      form: {
        id: 0,
        first_name: "",
        last_name: "",
        description: "",
        image_profile: "",
        status: "",
        timeslots: [],
      },
      formDD: {},
      selectedType: "",
      itemTimeSlotEdit: {},
    };
  },
  created() {
    this.selectedType = JSON.parse(
      Decode.decode(localStorage.getItem("selectedType"))
    );
    console.log("selectedType", this.selectedType);
    var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
    this.getAllbanners();
    // this.getEndDate();
  },
  methods: {
    formatDate(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    async getAllbanners() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/doctors?usertype=${this.selectedType}`
      );
      console.log("banners", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
      let dateTemp = [];
      // for (let i in this.items) {
      //   if (
      //     this.formatDate(this.items[i].period_start) <= this.currentDate &&
      //     this.formatDate(this.items[i].period_end) >= this.currentDate
      //   ) {
      //     this.items[i].status = true;
      //   } else {
      //     this.items[i].status = false;
      //   }
      //   this.items = this.items;
      // }
    },
    convertDate(val) {
      return moment(val).format("MMM Do YYYY");
    },
    getEndDate() {
      this.form.endDate = new Date(
        this.form.date.getFullYear(),
        this.form.date.getMonth() + 1,
        10
      );
      return this.form.endDate.toISOString().slice(0, 10);
    },
    Updatebanners(val) {
      console.log(val, "val");
      localStorage.setItem("doctorData", Encode.encode(val));
      this.isEdit = "edit";
      this.itemEdit = val;
      this.dialogNewDoctor = true;
      // this.$router.push("Editbanners");
    },
    UpdateTimeSlot(val) {
      // console.log('VAL',val);
      localStorage.setItem("itemTimeSlotEdit", Encode.encode(val));
      this.$router.push("/timeslot");
      // this.dialogtimeslot = true;
    },
    Viewbanners(val) {
      localStorage.setItem("Blogdata", Encode.encode(val));
      this.$router.push("Viewbanners");
    },
    async Deletebanners(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiAdmin"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/doctors/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbanners();
        }
      });
    },

    closeDialog() {
      this.dialogNewDoctor = false;
      this.itemEdit = null;
      this.isEdit = "";
    },
    closedialogtimeslot() {
      this.dialogtimeslot = false;
      // this.itemEdit = null;
      // this.isEdit = "";
    },
    dialogStepFormOpen() {
      // this.clearDialog();
      this.itemEdit = null;
      this.dialogNewDoctor = true;
    },
    clearDialog() {
      this.form = [];
      // this.form = {
      //   banner_title: "",
      //   period_start: new Date().toISOString().substr(0, 10),
      //   period_end: "",
      //   pic1: "",
      //   bannerImg: "",
      //   bannerUrl: "",
      //   menufrom: false,
      //   menuto: false,
      //   status: true,
      // };
    },
    cleardialogtimeslot() {
      // this.form = [];
      // this.form = {
      //   banner_title: "",
      //   period_start: new Date().toISOString().substr(0, 10),
      //   period_end: "",
      //   pic1: "",
      //   bannerImg: "",
      //   bannerUrl: "",
      //   menufrom: false,
      //   menuto: false,
      //   status: true,
      // };
    },
    async Status(val) {
      console.log("item", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const data = {
        // banner_title: val.banner_title,
        status: val.status,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/doctors/${val.id}`,
        data,
        auth
      );
      console.log("response", response.data.data);
    },
  },
};
</script>

<style scoped>
table.v-table thead th td {
  font-size: 14px !important;
}
</style>
