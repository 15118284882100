<template>
  <v-dialog v-model="dialog" max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        v-on="on"
        class="rounded-xl"
        style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
      >
        <span class="text-center mx-4 fn14-weight7">
          <a-icon type="plus" class="mr-2" />
          Add New Category 
        </span>
      </v-btn>
    </template>
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <v-row v-if="stepForm === 1" class="pa-4" style="background: white">
        <v-col cols="8" class="mx-auto">
          <div class="fn20-weight7">Add New Category</div>
        </v-col>

        <v-col cols="8" class="mx-auto">
          <!-- category_name -->
          <v-text-field
            v-model="form.category_name"
            hide-details
            outlined
            dense
            placeholder="New Category Name*"
          />
        </v-col>
        <v-col cols="8" class="mx-auto">
          <v-switch
            v-model="form.isvet"
            inset
            color="#1A347F"
            label="แสดงเฉพาะสัตว์แพทย์เท่านั้น"
          />
        </v-col>
        <v-col cols="12" class="pa-0" align="center" justify="center">
          <v-btn
            class="rounded-xl mr-2"
            style="color: #9bbef2"
            outlined
            width="250"
            @click="cancel"
          >
            <span class="text-center fn14-weight7"> cancel </span>
          </v-btn>
          <v-btn
            :dark="form.name === '' ? false : true"
            class="rounded-xl"
            :style="
              form.name === ''
                ? ''
                : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
            "
            width="250"
            @click="submit()"
          >
            <!-- @click="next" -->
            <span class="text-center fn14-weight7"> Add </span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="stepForm === 2" class="pa-4" style="background: white">
        <v-col cols="8" class="mx-auto">
          <div class="fn20-weight7">Add New Category</div>
        </v-col>
        <v-col cols="8" class="mx-auto pb-0">
          <v-text-field
            v-model="form.categoryName"
            hide-details
            outlined
            dense
            placeholder="New Category Name*"
          />
        </v-col>
        <v-col cols="8" class="mx-auto">
          <v-switch
            v-model="form.status"
            inset
            color="#1A347F"
            label="Top Category"
            hide-details
          />
        </v-col>
        <v-col cols="8" class="pb-0 mx-auto">
          <div class="fn20-weight7">Choose a Sub-Category</div>
        </v-col>
        <v-col cols="8" class="py-0 mx-auto">
          <v-radio-group hide-details mandatory>
            <v-radio
              color="#07204B"
              label="Parasiticide"
              value="parasiticide"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="8" class="py-0 px-8 mx-auto">
          <v-radio-group v-model="form.type" mandatory>
            <v-radio
              color="#07204B"
              label="Parasiticide"
              value="parasiticide"
            ></v-radio>
            <v-radio
              color="#07204B"
              label="Therapeutic"
              value="therapeutic"
            ></v-radio>
            <v-radio color="#07204B" label="Vaccine" value="vaccine"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12" class="" align="center" justify="center">
          <v-btn
            class="rounded-xl mr-2"
            style="color: #9bbef2"
            outlined
            width="250"
            @click="dialog = false"
          >
            <span class="text-center fn14-weight7"> cancel </span>
          </v-btn>
          <v-btn
            :dark="form.categoryName === '' ? false : true"
            :disabled="form.categoryName === ''"
            class="rounded-xl"
            :style="
              form.categoryName === ''
                ? ''
                : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
            "
            width="250"
            @click="submit()"
          >
            <span class="text-center fn14-weight7"> Upload </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {
    form: Object,
  },
  data() {
    return {
      dialog: false,
      start: 1,
      stepForm: 1,

      menufrom: false,
      product: "",
      items: [],
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,

      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  created() {
    // this.getAllProduct();
  },

  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/
          `
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
    },
    // async getAllProduct() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/findAllAdmin?brandId=` +
    //       this.brand +
    //       "&typeId=" +
    //       this.type +
    //       "&categoryId=" +
    //       this.category
    //     // 'http://localhost:8081/types/'
    //   );
    //   console.log("getAllproducts", response.data);
    //   this.items = response.data.data;
    //   for (let i in this.items) {
    //     this.items[i].count = parseInt(i) + 1;
    //     //console.log(this.list);
    //   }
    // },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.picture_1.splice(1, 1);
    },
    next() {
      this.stepForm += 1;
    },
    cancel() {
      // this.$router.push("Managebanners");
      this.start = 1;
      this.stepForm = 1;
      this.form = {
        name: "",
        date: new Date().toISOString().substr(0, 10),
        url: "",
        picture_1: "",
        menufrom: false,
        publish: false,
        type: null,
        categoryName: "",
      };
      this.dialog = false;
    },
    async submit() {
      const selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      console.log("selectedType", selectedType);
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiAdmin"))
        );
        const auth = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };
        console.log("this.form", this.form);
        this.form = {
          ...this.form,
          usertype: selectedType,
        };
        // ?usertype=${selectedType}
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/digital_category_1`,
          this.form,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างหมวดหมู่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.dialog = false;
          this.$emit("getAllCate1");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างหมวดหมู่ไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      // this.start = 1;
      // this.stepForm = 1;
      // this.form = {
      //   name: "",
      //   date: new Date().toISOString().substr(0, 10),
      //   url: "",
      //   picture_1: "",
      //   menufrom: false,
      //   publish: false,
      //   type: null,
      //   categoryName: "",
      // };
      this.dialog = false;
    },
  },
};
</script>
