<template>
  <v-dialog
    @click:outside="cancel"
    v-model="dialog"
    max-width="900px"
    :retain-focus="false"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        v-on="on"
        class="rounded-xl"
        style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
      >
        <span class="text-center mx-4 fn14-weight7">
          <a-icon type="plus" class="mr-2" />
          Create New Post
        </span>
      </v-btn>
    </template> -->
    <v-form v-model="checkValidate">
      <!-- <v-row v-if="stepForm === 1" class="pa-4" style="background: white">
        <v-col cols="8" class="mx-auto">
          <div class="fn20-weight7" style="color: #07204b">
            Select Post Type
          </div>
        </v-col>
        <v-col cols="8" class="pa-0 mx-auto">
          <v-radio-group v-model="form.type" mandatory :rules="rules.required">
            <v-radio color="#07204B" label="Video" value="video"></v-radio>
            <v-radio color="#07204B" label="Image" value="image"></v-radio>
            <v-radio color="#07204B" label="PDF" value="pdf"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" class="pa-0" align="center" justify="center">
          <v-btn
            class="rounded-xl mr-2"
            style="color: #9bbef2"
            outlined
            width="250"
            @click="cancel"
          >
            <span class="text-center fn14-weight7"> cancel </span>
          </v-btn>
          <v-btn
            :dark="form.type === '' ? false : true"
            :disabled="form.type === ''"
            class="rounded-xl"
            :style="
              form.type === ''
                ? ''
                : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
            "
            width="250"
            @click="next"
          >
            <span class="text-center fn14-weight7">
              {{ isEdit ? "Edit" : "Add" }}
            </span>
          </v-btn>
        </v-col>
      </v-row> -->
      <v-row v-if="stepForm === 2" class="pa-4" style="background: white">
        <v-col cols="6">
          <div class="fn20-weight7" v-if="isEdit">Edit Post</div>
          <div class="fn20-weight7" v-else>Create New Post</div>
        </v-col>

        <v-stepper v-model="start" vertical width="100%">
          <v-stepper-step :complete="start > 1" step="1" class="fn16">
            Post Information
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row style="color: #1a347f" class="fn16">
              <v-col cols="12" class="pb-0"> Post Name*: </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="form.digital_name"
                  hide-details
                  outlined
                  dense
                  placeholder="type your title here"
                  required
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" class="pb-0">Description*:</v-col>
              <v-col cols="12" class="pb-0">
                <v-textarea
                  v-model="form.description"
                  hide-details
                  outlined
                  dense
                  rows="3"
                  placeholder="Description"
                />
              </v-col>
              <v-col cols="12" class="pb-0">Choose Category*:</v-col>
              <v-col cols="12" class="mb-4">
                <v-select
                  v-model="form.digitalCategory1Id"
                  @change="selectedOne(form.digitalCategory1Id)"
                  item-text="category_name"
                  item-value="id"
                  hide-details
                  outlined
                  dense
                  :items="cate1"
                  placeholder="Select category"
                  :rules="rules.required"
                ></v-select>
                <!-- @change="getCategory2(form.digitalCategory1Id)" -->
                <!-- v-if="form.digitalCategory1Id" -->
                <v-select
                  v-model="form.digitalCategory2Id"
                  @change="selectedTwo(form.digitalCategory2Id)"
                  item-text="category_name"
                  item-value="id"
                  hide-details
                  outlined
                  dense
                  :items="cate2"
                  placeholder="Select category"
                  :rules="rules.required"
                ></v-select>
                <!-- @change="getCategory3(form.digitalCategory2Id)" -->
                <v-select
                  v-model="form.digitalCategory3Id"
                  item-text="category_name"
                  item-value="id"
                  hide-details
                  outlined
                  dense
                  :items="cate3"
                  placeholder="Select category"
                  :rules="rules.required"
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" class="pb-0">Point*:</v-col>
              <v-col cols="12" class="mb-4">
                <v-text-field
                  v-model="form.point"
                  outlined
                  dense
                  hide-details
                  type="number"
                  placeholder="Point"
                  :rules="rules.required"
                />
              </v-col> -->
            </v-row>
            <v-btn
              color="primary"
              @click="start = 2"
              :disabled="!form.digital_name || !form.digitalCategory3Id"
            >
              Continue
            </v-btn>
            <v-btn @click="stepForm = 1"> Cancel </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="start > 2" step="2">
            <!-- File,  -->
            link & Images
          </v-stepper-step>
          <!-- {{ form.type }} -->
          <v-stepper-content step="2">
            <v-col cols="12" class="pa-0">
              <v-row no-gutters align="center" justify="center">
                <!-- <v-col cols="12" class="mt-5 mx-10 my-5" id="step-3">
                  <v-img
                    v-model="form.digital_file_url"
                    @click="changePic2()"
                    src="@/assets/icons/upload.png"
                    class="mr-3 d-inline-block"
                    max-width="18"
                    max-height="22"
                  >
                  </v-img>
                  <span
                    style="color: #1a347f"
                    class="fn16-weight7"
                    @click="changePic2()"
                  >
                    Upload files
                  </span>

                  <span v-if="showImage2" class="green--text ml-4"
                    >อัพโหลดไฟล์สำเร็จ {{ showImage2 }}</span
                  >
                  <br />
                  <span style="color: red" class="fn14">ใช้นามสกุล .pdf </span>
                  <v-col
                    cols="12"
                    md="3"
                    class="pl-5 pr-5 row fill-height align-center sortable-list"
                  >
                  </v-col>
                  <input
                    type="file"
                    ref="file"
                    id="picTure2"
                    accept="application/pdf"
                    @change="showPicture2($event)"
                    style="display: none"
                    hidden
                  />
                </v-col> -->
                <v-col cols="4" class="py-0">
                  <div style="color: #1a347f" class="fn16-weight7">
                    Attach the link
                  </div>
                </v-col>
                <v-col cols="8" class="py-0">
                  <v-text-field
                    v-model="form.digital_file_url"
                    hide-details
                    outlined
                    dense
                    placeholder="Paste your link here"
                  />
                </v-col>
                <v-col cols="12" class="mt-5 ma-10" id="step-3">
                  <v-img
                    v-model="form.digitalImg"
                    @click="changePic1()"
                    src="@/assets/icons/upload.png"
                    class="mr-3 d-inline-block"
                    max-width="18"
                    max-height="22"
                  >
                  </v-img>
                  <span
                    style="color: #1a347f"
                    class="fn16-weight7"
                    @click="changePic1()"
                  >
                    Upload Cover Image
                  </span>
                  <br />
                  <span style="color: red" class="fn14">
                    ใช้ขนาด 400x300 px</span
                  >
                  <v-col
                    cols="12"
                    md="3"
                    class="pl-5 pr-5 row fill-height align-center sortable-list"
                  >
                    <v-card dense light class="pa-1" v-if="showImage">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImage"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>
                      <v-img
                        :src="showImage"
                        width="200px"
                        aspect-ratio="1.8"
                        contain
                      ></v-img>
                    </v-card>
                  </v-col>
                  <input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/*"
                    @change="showPicture1($event)"
                    style="display: none"
                    hidden
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-btn color="primary" class="mr-2" @click="start = 3">
              Continue
            </v-btn>
            <v-btn @click="start = 1"> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper>
        <v-col cols="12" class="" align="center" justify="center">
          <v-btn
            class="rounded-xl mr-2"
            style="color: #9bbef2"
            outlined
            width="250"
            @click="cancel"
          >
            <span class="text-center fn14-weight7"> Cancel </span>
          </v-btn>
          <v-btn
            :dark="start < 3 ? false : true"
            :disabled="start < 3"
            class="rounded-xl"
            :style="
              start < 3
                ? ''
                : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
            "
            width="250"
            @click="submit()"
          >
            <span class="text-center fn14-weight7">
              {{ isEdit ? "Edit" : "Add" }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-dialog>
</template>

<script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {
    form: Object,
    dialog: Boolean,
    isEdit: {
      // type: String,
      default: "",
    },
    items: Object,
  },
  data() {
    return {
      cate1: [],
      cate2: [],
      cate3: [],
      dialog: false,
      start: 1,
      radios: null,
      stepForm: 2,

      menufrom: false,
      product: "",
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      pic2: "",
      picture_1: "",
      showImage: "",
      showImage2: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      idEdit: "",
      selectedType: "",
    };
  },
  created() {
    this.selectedType = JSON.parse(
      Decode.decode(localStorage.getItem("selectedType"))
    );
    console.log("selectedType", this.selectedType);
    this.getCategory1();
  },

  watch: {
    async dialog(val) {
      console.log("dialog");
      if (val) {
        if (this.isEdit === "edit") {
          await this.getRewardById(this.items.id);
          await this.setdigitalCategory();
        } else {
          this.$emit("clear");
        }
      }
    },

    // stepForm(val) {
    //   console.log({ val });
    //   if (val === 2) {
    //     console.log(this.isEdit, "edit");
    //     console.log(this.form, "form");
    //     this.getRewardById();
    //     this.setdigitalCategory();
    //   }
    // },
    // items: {
    //   handler(val) {
    //     console.log(val);
    //     // if (this.isEdit === "edit") {
    //     //   this.getRewardById(val.id);
    //     // } else {
    //     //   this.$emit("clear");
    //     // }
    //   },
    //   deeps: true,
    // },
    // async "form.digitalCategory1Id"(item) {
    //   console.log("object1");
    //   if (item) {
    //     const response = await this.axios.get(
    //       `${process.env.VUE_APP_API}/digital_category_2?digitalCategory1Id=${item}`
    //     );
    //     console.log("cate2", response.data);
    //     this.cate2 = response.data.data;
    //   }
    // },
    // async "form.digitalCategory2Id"(item) {
    //   console.log("object2");
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/digital_category_3?digitalCategory2Id=${item}`
    //   );
    //   console.log("cate3", response.data);
    //   this.cate3 = response.data.data;
    // },
    // "form.type"(val) {
    //   console.log("form.type", val);
    // },
  },
  methods: {
    async setdigitalCategory() {
      await this.selectedOne(this.form.digitalCategory1Id);
      await this.selectedTwo(this.form.digitalCategory2Id);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    // test type
    async getCategory1() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/digital_category_1?usertype=${this.selectedType}
          `
      );
      console.log("cate1", this.selectedType, response.data);
      this.cate1 = response.data.data;
    },
    // async getCategory2(item) {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/digital_category_2?digitalCategory1Id=${item}`
    //   );
    //   console.log("cate2", response.data);
    //   this.cate2 = response.data.data;
    // },
    // async getCategory3(item) {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/digital_category_3?digitalCategory2Id=${item}`
    //   );
    //   console.log("cate3", response.data);
    //   this.cate3 = response.data.data;
    // },
    async showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;

      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(element);
        // reader.onload = () => {
        //   this.pic1 = reader.result;
        //   this.showImage = URL.createObjectURL(element);
        // };
        console.log(element);

        // var user = JSON.parse(
        //   Decode.decode(localStorage.getItem("userBiAdmin"))
        // );
        // const auth = {
        //   headers: {
        //     Authorization: `Bearer ${user.token}`,
        //   },
        // };

        // const response = await this.axios.get(
        //   `${process.env.VUE_APP_API}/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   auth
        // );

        // console.log("response", response);
        // console.log("url", response.data);
        let formData = new FormData();
        formData.append("files", element);
        const responseUrl = await this.axios.post(
          `https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/files/uploadFormData`,
          formData
        );
        console.log("responseUrl", responseUrl.data.data);
        this.imageType = element.type;
        this.showImage = responseUrl.data.data.url;
        // /reward/upload-url-img/?contentType=jpg&image_name=TEST_IMG
      }
    },
    async showPicture2(e) {
      // console.log('index',index);
      const files = e.target.files;

      if (files[0] !== undefined) {
        this.pic2 = files[0].name;
        const element = files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(element);
        // reader.onload = () => {
        //   this.pic1 = reader.result;
        //   this.showImage = URL.createObjectURL(element);
        // };
        console.log(element);

        // var user = JSON.parse(
        //   Decode.decode(localStorage.getItem("userBiAdmin"))
        // );
        // const auth = {
        //   headers: {
        //     Authorization: `Bearer ${user.token}`,
        //   },
        // };

        // const response = await this.axios.get(
        //   `${process.env.VUE_APP_API}/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   auth
        // );

        let formData = new FormData();
        formData.append("files", element);
        const responseUrl = await this.axios.post(
          `https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/files/uploadFormData`,
          formData
        );
        console.log("responseUrl", responseUrl.data.data);
        this.imageType = element.type;
        this.showImage2 = responseUrl.data.data.url;
        // /reward/upload-url-img/?contentType=jpg&image_name=TEST_IMG
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.picture_1.splice(1, 1);
    },
    RemoveImage2() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.picture_2.splice(1, 1);
    },
    next() {
      // if (this.$refs.createForm.validate(true)) {
      this.stepForm += 1;
      // }
    },
    cancel() {
      // this.$router.push("Managebanners");
      if (this.isEdit) {
        this.start = 1;
        this.stepForm = 1;
        this.showImage = "";
        this.showImage2 = "";
        this.$emit("close");
      } else {
        this.start = 1;
        this.stepForm = 1;
        this.showImage = "";
        this.showImage2 = "";
        // this.form = {
        //   name: "",
        //   date: new Date().toISOString().substr(0, 10),
        //   url: "",
        //   picture_1: "",
        //   menufrom: false,
        //   publish: false,
        //   type: null,
        // };
        this.$emit("close");
        this.$emit("clear");
      }
    },
    async submit() {
      // if (this.$refs.createForm.validate(true)) {
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      this.form = {
        ...this.form,
        status: true,
        digitalImg: this.showImage,
        digital_video_url:
          this.showImage2 != "" ? this.showImage2 : this.form.digital_video_url,
        usertype: this.selectedType,
      };

      if (this.isEdit === "edit") {
        this.form = {
          ...this.form,
          id: this.idEdit,
        };
      }
      if (this.isEdit === "edit") {
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/digital/${this.idEdit}`,
          this.form,
          auth
        );
        console.log("edit", response);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            iconColor: "#1a347f",
            text: `แก้ไขdigital hubสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("reloadTable");
          // this.$router.push("Managedigital");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขdigital hubไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.stepForm = 1;
        this.showImage = "";
        this.showImage2 = "";
        this.$emit("close");
        this.$emit("clear");
      } else {
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/digital?usertype=${this.selectedType}`,
          this.form,
          auth
        );
        console.log("createCate", response);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            iconColor: "#1a347f",
            text: `สร้างdigital hubสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });

          this.$emit("reloadTable");
          this.$router.push("Managedigital");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.stepForm = 1;
        this.showImage = "";
        this.showImage2 = "";
        this.$emit("close");
        this.$emit("clear");
      }
      // }
      // this.form = {
      //   name: "",
      //   date: new Date().toISOString().substr(0, 10),
      //   url: "",
      //   picture_1: "",
      //   menufrom: false,
      //   publish: false,
      //   type: null,
      // };
      // this.dialog = false;
      // }
    },
    async selectedOne(val) {
      console.log(val, 1);
      if (val) {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/digital_category_2?digitalCategory1Id=${val}`
        );
        console.log("cate2", response.data);
        this.cate2 = response.data.data;
      }
    },
    async selectedTwo(val) {
      console.log(val, 2);
      if (val) {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/digital_category_3?digitalCategory2Id=${val}`
        );
        console.log("cate3", response.data);
        this.cate3 = response.data.data;
      }
    },
    async getRewardById(id) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/digital/` + id
      );
      console.log("getRewardById", response.data.data);
      const data = response.data.data;
      this.form.digitalCategory1Id = data.digitalCategory1Id;
      this.form.digitalCategory2Id = data.digitalCategory2Id;
      this.form.digitalCategory3Id = data.digitalCategory3Id;
      this.form.digital_name = data.digital_name;
      this.form.description = data.description;
      this.form.type = data.type;
      // this.form.point = data.point;
      this.form.digital_video_url = data.digital_video_url;
      this.form.digital_file_url = data.digital_file_url;
      this.showImage = data.digitalImg;
      this.showImage2 = data.digital_file_url;
      // วาป
      this.idEdit = data.id;
    },
  },
};
</script>
