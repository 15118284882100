<template>
  <!-- <v-row
    style="background-color: white"
    align="center"
    justify="center"
    class="text-center"
  > -->
  <!-- <v-form> -->
  <v-app
    style="background-color: white"
    align="center"
    justify="center"
    class="text-center pa-4"
  >
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-col cols="12" class="ma-0 pa-0">
        <span
          style="color: #1a347f; font-weight: 900; font-size: 32px;-webkit-center"
          >Forgot Password ?</span
        >
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <span
          style="color: #1A347F; font-weight: 400; font-size: 20px;-webkit-center"
          >Type your email we'll send a reset password link.
        </span>
      </v-col>
      <v-col cols="5">
        <!-- <v-row>
          <v-col cols="12"> -->
        <v-text-field
          v-model="email"
          label="Email*"
          outlined
          dense
          hide-details="auto"
          :rules="rules.emailRules"
        ></v-text-field>
        <!-- </v-col>
        </v-row> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          dark
          class="rounded-xl px-12"
          width="171"
          height="39"
          style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
          @click="submit()"
          >Submit</v-btn
        >
        <v-spacer></v-spacer>
        <br />
        <!-- </v-col>
      <v-col cols="12"> -->
        <v-btn
          text
          class="rounded-xl px-12"
          width="171"
          height="39"
          style="color: #1a347f"
          @click="cancel()"
          >cancel</v-btn
        >
      </v-col>
    </v-row>
    <br />

    <v-row>
      <v-col cols="12" style="align-self: self-end" class="pa-0 ma-0">
        <footerPage />
      </v-col>
    </v-row>
  </v-app>
</template>


<script>
import { Decode, Encode } from "@/services";
import footerPage from "@/components/Shared/footer";
export default {
  components: {
    footerPage,
  },
  data() {
    return {
      Livestock: false,
      Pet: false,
      Poultry: false,
      selected: "",
      password: "",
      username: "",
      confirmpassword: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phone: [
          (v) =>
            (v.length <= 10 && v.length >= 9) ||
            "หมายเลขโทรศัพท์ต้องมี 9-10 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน",
        ],

        password: [
          (v) =>
            v.length >= 8 ||
            " At least 8 characters—the more characters, the better",
          (v) => !!v || "Password is required",
          (v) =>
            !!v.match(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
            ) || "Your password is invalid according to the format.",
        ],

        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "This field is required",
        ],

        confirmPasswordRules: [(v) => !!v || "Password is required"],
      },
      selectedType: "",
    };
  },
  computed: {
    // passwordConfirmationRule() {
    //   return () =>
    //     this.password == this.confirmPassword || "Your password doesn't match";
    // },
  },
  created() {
    this.selectedType = this.$router.currentRoute.query.type;
    this.username = this.$router.currentRoute.query.email;
    console.log("this.selectedType", this.selectedType);
    // if (Object.prototype.hasOwnProperty.call(localStorage, "userBiAdmin")) {
    //   var userData = JSON.parse(
    //     Decode.decode(localStorage.getItem("userBiAdmin"))
    //   );
    //   if (userData.user.type !== "ADMIN") {
    //     localStorage.setItem("selectedType", Encode.encode(userData.user.type));
    //     var selectedType = JSON.parse(
    //       Decode.decode(localStorage.getItem("selectedType"))
    //     );
    //     console.log("selectedType", selectedType);
    //     this.$router.push("/Dashboard");
    //   }
    // }
  },
  methods: {
    // ธุรกิจสัตว์ปีก
    // ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง
    // ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป
    aaaaa(val) {
      console.log("val", val);
      if (val == "ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง") {
        this.Livestock = true;
        this.Pet = false;
        this.Poultry = false;
      } else if (val == "ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป") {
        this.Livestock = false;
        this.Pet = true;
        this.Poultry = false;
      } else if (val == "ธุรกิจสัตว์ปีก") {
        this.Livestock = false;
        this.Pet = false;
        this.Poultry = true;
      }
      this.selected = val;
    },
    cancel() {
      this.$router.push("/");
    },
    async submit() {
      this.$router.push("/forgotpasswordsuccess");
      // const data = {
      //   username: this.username,
      //   password: this.password,
      //   type: this.selectedType,
      //   admin_type: "ADMIN",
      //   activeFlag: true,
      // };
      // const response = await this.axios.post(
      //   `${process.env.VUE_APP_API}/users/forgotpassword`,
      //   data
      // );
      // console.log("response", response);
      
      // if (response.data.response_status == "SUCCESS") {
      //   // this.$swal.fire({
      //   //   icon: "success",
      //   //   text: `สร้างผู้ใช้งานสำเร็จ`,
      //   //   showConfirmButton: false,
      //   //   timer: 1500,
      //   // });
      //   this.$router.push("/forgotpasswordsuccess");
      // } else {
      //   this.$swal.fire({
      //     icon: "error",
      //     text: "ไม่พบผู้ใช้งาน",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // }
    },
  },
};
</script>

<style scoped>
.tab-color {
  border: 2px solid #1a347f;
  box-sizing: border-box;
  border-radius: 4px;
}
/* 
.tab-color {
  border: 5px solid #9f9f9f;
  border-radius: 55px;
} */
</style>