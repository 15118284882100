<template>
  <v-dialog
    v-model="dialogNewDoctor"
    max-width="900px"
    :retain-focus="false"
    persistent
  >
    <v-form ref="createForm" v-model="form.is_Validate" :lazy-validation="lazy">
      <v-row class="pa-4" style="background: white">
        <v-col cols="6">
          <div class="fn20-weight7">Consultant Detail</div>
        </v-col>

        <v-divider></v-divider>

        <v-stepper v-model="start" vertical width="100%">
          <v-stepper-step :complete="start > 1" step="1" class="fn16">
            Consultant Info
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row class="px-6 py-4" style="color: #1a347f">
              <v-col cols="2">
                <v-row align="center" justify="center">
                  <v-avatar size="90">
                    <v-img
                      :src="showImage ? showImage : defaultImage"
                      max-width="80px"
                      max-height="80px"
                      @click="changePic1()"
                    >
                      <input
                        type="file"
                        ref="image"
                        id="picTure1"
                        accept="image/*"
                        @change="showPicture1($event)"
                        style="display: none"
                        hidden
                    /></v-img>
                  </v-avatar>
                </v-row>
              </v-col>
              <v-col cols="10">
                <v-row>
                  <v-col cols="6" class="pb-0">First Name*:</v-col>
                  <v-col cols="6" class="pb-0">Last Name*:</v-col>
                  <v-col cols="6" class="pb-0">
                    <v-text-field
                      v-model="form.first_name"
                      hide-details
                      outlined
                      dense
                      placeholder="Dekdee"
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <v-text-field
                      v-model="form.last_name"
                      hide-details
                      outlined
                      dense
                      placeholder="JaiDee"
                      :rules="rules.required"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="10" offset="2">
                <v-row>
                  <v-col cols="12" class="pb-0">Email*:</v-col>

                  <v-col cols="6" class="pb-0">
                    <v-text-field
                      v-model="form.email"
                      hide-details
                      outlined
                      dense
                      type="email"
                      placeholder="test@mail.com"
                    />
                    <!-- :rules="rules.required" -->
                  </v-col>
                </v-row>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" class="pb-0">Description</v-col>
              <v-col cols="12">
                <!-- <v-textarea v-model="form.description" outlined dense>
                </v-textarea> -->
                <quill-editor
                  :content="form.description"
                  :options="editorOption"
                  @change="onEditorChange($event)"
                />
              </v-col>
              <v-divider></v-divider>
            </v-row>
            <v-btn
              color="primary"
              @click="doRequire()"
              :disabled="
                !form.first_name || !form.last_name || !form.description
              "
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <!-- <v-stepper-step :complete="start > 2" step="2">
            Available Time
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-row class="py-6 px-5">
              <v-col cols="12" class="pa-0">
                <span>Select Dat</span>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-chip
                  v-model="form.is_sun"
                  outlined
                  :color="form.is_sun ? 'green' : ''"
                  class="ma-1"
                  small
                  @click="form.is_sun = !form.is_sun"
                  >Every Sunday</v-chip
                >
                <v-chip
                  v-model="form.is_mon"
                  outlined
                  :color="form.is_mon ? 'green' : ''"
                  class="ma-1"
                  small
                  @click="form.is_mon = !form.is_mon"
                  >Every Monday</v-chip
                >
                <v-chip
                  v-model="form.is_tue"
                  outlined
                  :color="form.is_tue ? 'green' : ''"
                  class="ma-1"
                  small
                  @click="form.is_tue = !form.is_tue"
                  >Every Tuesday</v-chip
                >
                <v-chip
                  v-model="form.is_wed"
                  outlined
                  :color="form.is_wed ? 'green' : ''"
                  class="ma-1"
                  small
                  @click="form.is_wed = !form.is_wed"
                  >Every Wednesday</v-chip
                >
                <v-chip
                  v-model="form.is_thu"
                  outlined
                  :color="form.is_thu ? 'green' : ''"
                  class="ma-1"
                  small
                  @click="form.is_thu = !form.is_thu"
                  >Every Thursday</v-chip
                >
                <v-chip
                  v-model="form.is_fri"
                  outlined
                  :color="form.is_fri ? 'green' : ''"
                  class="ma-1"
                  small
                  @click="form.is_fri = !form.is_fri"
                  >Every Friday</v-chip
                >
                <v-chip
                  v-model="form.is_sat"
                  outlined
                  :color="form.is_sat ? 'green' : ''"
                  class="ma-1"
                  small
                  @click="form.is_sat = !form.is_sat"
                  >Every Saturday</v-chip
                >
              </v-col>

              <v-col cols="12"><v-divider /></v-col>
              <v-col cols="12" class="pa-0">
                <span>Select Time</span>
              </v-col>
              <v-row>
                <v-col cols="12">
                  <v-chip
                    v-for="(i, index) in form.time_slot"
                    :key="index"
                    outlined
                    class="ma-1"
                    small
                    :color="i.is_avalible ? 'green' : ''"
                    @click="i.is_avalible = !i.is_avalible"
                    >{{ i.time }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-row>
            <v-btn
              color="primary"
              class="mr-2"
              @click="start = 3"
              :disabled="
                !form.is_sun &&
                !form.is_mon &&
                !form.is_tue &&
                !form.is_wed &&
                !form.is_thu &&
                !form.is_fri &&
                !form.is_sat &&
                !form.is_slot_9 &&
                !form.is_slot_10 &&
                !form.is_slot_12 &&
                !form.is_slot_13 &&
                !form.is_slot_14 &&
                !form.is_slot_15 &&
                !form.is_slot_16 &&
                !form.is_slot_17
              "
            >
              Continue
            </v-btn>
            <v-btn @click="start = 1"> Cancel </v-btn>
          </v-stepper-content> -->
        </v-stepper>

        <v-col cols="12" class="px-0 pb-0" align="center" justify="center">
          <v-btn
            class="rounded-xl mr-2"
            style="color: #9bbef2"
            outlined
            width="250"
            @click="cancel"
          >
            <span class="text-center fn14-weight7"> cancel </span>
          </v-btn>
          <v-btn
            dark
            class="rounded-xl"
            style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
            width="250"
            @click="submit()"
          >
            <span class="text-center fn14-weight7"> Upload </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
  },
  props: {
    dialogNewDoctor: {
      type: Boolean,
      default: false,
    },
    form: Object,

    isEdit: {
      type: String,
      default: "",
    },
    items: Object,
  },
  data() {
    return {
      defaultImage: require("@/assets/images/doctor.png"),
      is_Validate: true,
      lazy: false,
      dialogNewDoctor: false,
      start: 1,
      product: "",
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      bannerImg: "",
      showImage: "",
      imageType: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      content: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
          pic1: "",
          picture_1: "",
          showImage: "",
        },
      },

      idEdit: "",
      selectedType: "",
      // form: {
      //   first_name: "first_name",
      //   last_name: "last_name",
      //   description: "",
      //   image_profile: "",
      //   status: "",
      //   is_sun: true,
      //   is_mon: true,
      //   is_tue: true,
      //   is_wed: false,
      //   is_thu: false,
      //   is_fri: false,
      //   is_sat: false,
      // },
    };
  },
  watch: {
    "form.date"(val) {
      console.log("val[0]", val[0]);
      console.log("val[1]", val[1]);
    },
    items: {
      handler(val) {
        if (this.isEdit === "edit") {
          this.getDoctorById(val.id);
          console.log(val.id);
        }
      },
      deeps: true,
    },
  },
  // computed: {
  //   "form.showdate"() {
  //     return this.form.showDate[0], this.form.showDate[1];
  //   },
  // },

  methods: {
    RemoveImage() {
      this.form.picture_1.splice(1, 1);
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    async showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;

      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(element);
        // reader.onload = () => {
        //   this.pic1 = reader.result;
        //   this.showImage = URL.createObjectURL(element);
        // };
        console.log(element);

        // var user = JSON.parse(
        //   Decode.decode(localStorage.getItem("userBiAdmin"))
        // );
        // const auth = {
        //   headers: {
        //     Authorization: `Bearer ${user.token}`,
        //   },
        // };

        // const response = await this.axios.get(
        //   // `${process.env.VUE_APP_API}/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   `https://service.biahmemberclub.com/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   auth
        // );

        // console.log("response", response);
        // console.log("url", response.data);
        let formData = new FormData();
        formData.append("files", element);
        const responseUrl = await this.axios.post(
          `https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/files/uploadFormData`,
          formData
        );
        console.log("responseUrl", responseUrl.data.data);
        this.imageType = element.type;
        this.showImage = responseUrl.data.data.url;
        // /reward/upload-url-img/?contentType=jpg&image_name=TEST_IMG
      }
    },
    doRequire() {
      if (this.$refs.createForm.validate(true)) {
        this.start = 2;
      }
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.form.description = html;
    },
    async showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;

      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(element);
        // reader.onload = () => {
        //   this.pic1 = reader.result;
        //   this.showImage = URL.createObjectURL(element);
        // };
        console.log(element);

        // var user = JSON.parse(
        //   Decode.decode(localStorage.getItem("userBiAdmin"))
        // );
        // const auth = {
        //   headers: {
        //     Authorization: `Bearer ${user.token}`,
        //   },
        // };

        // const response = await this.axios.get(
        //   // `${process.env.VUE_APP_API}/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   `https://service.biahmemberclub.com/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
        //   auth
        // );

        // console.log("response", response);
        // console.log("url", response.data.url);
        let formData = new FormData();
        formData.append("files", element);
        const responseUrl = await this.axios.post(
          `https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/files/uploadFormData`,
          formData
        );
        console.log("responseUrl", responseUrl.data.data);
        this.imageType = element.type;
        this.showImage = responseUrl.data.data.url;
        // /reward/upload-url-img/?contentType=jpg&image_name=TEST_IMG
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.bannerImg.splice(1, 1);
    },

    cancel() {
      this.start = 1;
      this.showImage = "";
      this.$emit("closeDialog");

      if (this.isEdit !== "edit") {
        this.$emit("clear");
      }
    },
    async submit() {
      const selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      // period_end: null;
      // period_start: null;
      // publish_date: null;
      this.form = {
        ...this.form,
        image_profile: this.showImage,
        // period_start: this.form.period_start,
        // period_end: this.form.period_end,
        // bannerImg: this.showImage,
        // type: this.imageType,
        // status: 'Available',
        // usertype: selectedType,
      };
      if (this.isEdit === "edit") {
        this.form = {
          ...this.form,
          image_profile: this.showImage,
          id: this.idEdit,
          status: "Available",
          usertype: selectedType,
        };
      }
      if (this.isEdit === "edit") {
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/doctors/${this.idEdit}`,
          this.form,
          auth
        );
        console.log("edit", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            iconColor: "#1a347f",
            text: `Edit Consultant Success`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllbanners");
          this.$router.push("ManageOC");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `Edit Consultant Unsuccess`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.showImage = "";
        this.$emit("clearDialog");
        this.$emit("closeDialog");
      } else {
        this.form = {
          ...this.form,
          image_profile: this.showImage,
          // period_start: this.form.period_start,
          // period_end: this.form.period_end,
          // bannerImg: this.showImage,
          // type: this.imageType,
          status: "Available",
          usertype: selectedType,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/doctors`,
          this.form,
          auth
        );
        console.log("creat", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            iconColor: "#1a347f",
            text: `เพิ่มConsultantสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllbanners");
          // this.$router.push("ManageOC");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `เพิ่มConsultantไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.showImage = "";
        this.$emit("clearDialog");
        this.$emit("closeDialog");
      }
    },

    async getDoctorById(id) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/doctors/` + id
      );
      const { data } = response.data;
      console.log("responseById", data);

      this.form.image_profile = data.image_profile;
      // this.form.bannerUrl = data.bannerUrl;
      this.idEdit = data.id;
      this.showImage = data.image_profile;
      this.form = data;
      // this.form.first_name = data.first_name;
      // this.form.last_name = data.last_name;
      // this.form.description = data.description;
      // this.form.status = data.status;
    },
    convertDate(val) {
      return moment(val).format("MM-DD-YYYY");
    },
  },
};
</script>
