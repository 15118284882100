<template>
  <!-- <v-row align="center" justify="center" class="text-center"> -->
  <v-btn
    dark
    outlined
    class="rounded-xl"
    width="171"
    height="39"
    style="color: #1a347f"
  >
    <download-excel
      class="btn btn-default"
      :data="items"
      :fields="json_fields"
      worksheet="My Worksheet"
      name="Redemptions.xls"
      :header="'Redemptions'"
    >
      Export</download-excel
    >
  </v-btn>
  <!-- <v-btn class="ml-4 mb-4"
      ><download-excel
        class="btn btn-default"
        :data="list1"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="ผู้ใช้งานทั้งหมด.xls"
        :header="'ข้อมูลผู้ใช้งานทั้งหมด'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        ข้อมูลผู้ใช้งานทั้งหมด
      </download-excel></v-btn
    > -->
  <!-- </v-row> -->
</template>
<script>
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  // :formDate="datefrom"
  // :toDate="dateto"
  props: ["items"],
  data() {
    return {
      json_fields: {
        "Reward Name": "redemption_name",
        "ชื่อ-นามสกุล": "fullname",
        // ชื่อ: "firstname",
        // นามสกุล: "lastname",
        ที่อยู่: "fullAddress",
        
        // หมายเลขโทรศัพท์: "tel",
        หมายเลขโทรศัพท์: {
          field: "tel",
          callback: (value) => {
            if (value) {
              return "'" + value.toString();
            }
          },
        },
        // หมายเลขโทรศัพท์: {
        //   field: "tel",
        //   callback: (value) => {
        //     return "'" + value.toString();
        //   },
        // },
        แต้มที่แลก: "point",

        วันที่: {
          field: "date",
          callback: (value) => {
            return moment(value).format("MM/DD/YYYY hh:mm");
          },
        },
        "Tracking Number": "tracking_number",
        Status: "status",
        // address1: "233"
        // address2: null
        // create_by: null
        // createdAt: "2022-04-07T09:52:12.714Z"
        // date: "2022-04-07T09:52:12.714Z"
        // delevery_address: "1"
        // detail: null
        // district: "บางแค"
        // id: 32
        // point: 200
        // postcode: "10160"
        // province: "กรุงเทพ"
        // redemption_name: "T-shirt"
        // reward: Object
        // rewardId: 66
        // status: "Delivered"
        // subdistrict: "บางแค"
        // tel: null
        // tracking_number: "98765"
        // update_by: null
        // updatedAt: "2022-04-07T10:04:39.510Z"
        // user: Object
        // userId: 32
        // usertype: "ธุรกิจสัตว์ปีก"
      },

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getAllAdmins();
  },
  methods: {
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    // async getAllAdmins() {
    //   var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
    //   const auth = {
    //     headers: {
    //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
    //     },
    //   };
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/users/`,
    //     auth
    //   );
    //   console.log("users", response.data);
    //   this.list1 = response.data.data;
    //   for (let i in this.list1) {
    //     this.list1[i].count = parseInt(i) + 1;
    //   }
    // },
  },
};
</script>
