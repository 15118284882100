import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "@/views/Homepage.vue";
import login from "../views/Login.vue";
import Forgotpassword from "@/views/Forgotpassword.vue";
import ForgotpasswordSuccess from "@/views/ForgotpasswordSuccess.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "login",
        component: login
    },
    {
        path: "/Forgotpassword",
        name: "forgot",
        component: Forgotpassword
    },
    {
        path: "/ForgotpasswordSuccess",
        name: "forgotsuccess",
        component: ForgotpasswordSuccess
    },

    {
        path: "/SelectType",
        name: "SelectType",
        component: () => import ("@/views/SelectType.vue")
    }, {
        path: "/SetupPassword",
        name: "SetupPassword",
        component: () => import ("@/views/SetupPassword.vue")
    }, {
        path: "/ResetPassword",
        name: "ResetPassword",
        component: () => import ("@/views/SetupPasswordReset.vue")
    }, {
        path: "/ExportVat",
        name: "ExportVat",
        component: () => import ("@/views/ExportExcel/ExportVat.vue")
    }, {
        path: "/invc",
        name: "invc",
        component: () => import ("@/views/ExportExcel/ExportInvoiceeee.vue")
    }, {
        path: "/",
        component: Homepage,
        children: [
            {
                path: "/Managebanners",
                name: "Managebanners",
                component: () => import ("@/views/Managebanners")
            },
            {
                path: "/Createbanners",
                name: "Createbanners",
                component: () => import ("@/components/banners/Createbanners.vue")
            },
            {
                path: "/Viewbanners",
                name: "Viewbanners",
                component: () => import ("@/components/banners/Viewbanners.vue")
            },
            {
                path: "/Editbanners",
                name: "Editbanners",
                component: () => import ("@/components/banners/Editbanners.vue")
            }, {
                path: "/ManageMenu",
                name: "ManageMenu",
                component: () => import ("@/views/ManageMenu")
            }, {
                path: "/CreateMenu",
                name: "CreateMenu",
                component: () => import ("@/components/Menu/CreateMenu.vue")
            }, {
                path: "/EditMenu",
                name: "EditMenu",
                component: () => import ("@/components/Menu/EditMenu.vue")
            }, {
                path: "/ViewMenu",
                name: "ViewMenu",
                component: () => import ("@/components/Menu/ViewMenu.vue")
            }, {
                path: "/ManageQuestionnaire",
                name: "ManageQuestionnaire",
                component: () => import ("@/views/ManageQuestionnaire")
            }, {
                path: "/CreateQuestionnaire",
                name: "CreateQuestionnaire",
                component: () => import ("@/components/Questionnaire/CreateQuestionnaire.vue")
            }, {
                path: "/EditQuestionnaire",
                name: "EditQuestionnaire",
                component: () => import ("@/components/Questionnaire/EditQuestionnaire.vue")
            }, {
                path: "/ViewQuestionnaire",
                name: "ViewQuestionnaire",
                component: () => import ("@/components/Questionnaire/ViewQuestionnaire.vue")
            }, {
                path: "/ManageQuiz",
                name: "ManageQuiz",
                component: () => import ("@/views/ManageQuiz")
            }, {
                path: "/CreateQuiz",
                name: "CreateQuiz",
                component: () => import ("@/components/Quiz/CreateQuiz.vue")
            }, {
                path: "/EditQuiz",
                name: "EditQuiz",
                component: () => import ("@/components/Quiz/EditQuiz.vue")
            }, {
                path: "/ViewQuiz",
                name: "ViewQuiz",
                component: () => import ("@/components/Quiz/ViewQuiz.vue")
            }, {
                path: "/ManageCampaign",
                name: "ManageCampaign",
                component: () => import ("@/views/ManageCampaign")
            }, {
                path: "/CreateCampaign",
                name: "CreateCampaign",
                component: () => import ("@/components/Campaign/CreateCampaign.vue")
            }, {
                path: "/ViewCampaign",
                name: "ViewCampaign",
                component: () => import ("@/components/Campaign/ViewCampaign.vue")
            }, {
                path: "/EditCampaign",
                name: "EditCampaign",
                component: () => import ("@/components/Campaign/EditCampaign.vue")
            }, {
                path: "/ManageRewards",
                name: "ManageRewards",
                component: () => import ("@/views/ManageRewards")
            }, {
                path: "/CreateRewards",
                name: "CreateRewards",
                component: () => import ("@/components/Rewards/CreateRewards.vue")
            }, {
                path: "/EditRewards",
                name: "EditRewards",
                component: () => import ("@/components/Rewards/EditRewards.vue")
            }, {
                path: "/ViewRewards",
                name: "ViewRewards",
                component: () => import ("@/components/Rewards/ViewRewards.vue")
            }, {
                path: "/ManageKnowledge",
                name: "ManageKnowledge",
                component: () => import ("@/views/ManageKnowledge")
            }, {
                path: "/CreateKnowledge",
                name: "CreateKnowledge",
                component: () => import ("@/components/Knowledge/CreateKnowledge.vue")
            }, {
                path: "/ViewKnowledge",
                name: "ViewKnowledge",
                component: () => import ("@/components/Knowledge/ViewKnowledge.vue")
            }, {
                path: "/EditKnowledge",
                name: "EditKnowledge",
                component: () => import ("@/components/Knowledge/EditKnowledge.vue")
            }, {
                path: "/ManageELearning",
                name: "ManageELearning",
                component: () => import ("@/views/ManageELearning")
            }, {
                path: "/CreateELearning",
                name: "CreateELearning",
                component: () => import ("@/components/ELearning/CreateELearning.vue")
            }, {
                path: "/EditELearning",
                name: "EditELearning",
                component: () => import ("@/components/ELearning/EditELearning.vue")
            }, {
                path: "/ViewELearning",
                name: "ViewELearning",
                component: () => import ("@/components/ELearning/ViewELearning.vue")
            }, {
                path: "/ManageLoyalty",
                name: "ManageLoyalty",
                component: () => import ("@/views/ManageLoyalty")
            }, {
                path: "/CreateLoyalty",
                name: "CreateLoyalty",
                component: () => import ("@/components/Loyalty/CreateLoyalty.vue")
            }, {
                path: "/EditLoyalty",
                name: "EditLoyalty",
                component: () => import ("@/components/Loyalty/EditLoyalty.vue")
            }, {
                path: "/ViewLoyalty",
                name: "ViewLoyalty",
                component: () => import ("@/components/Loyalty/ViewLoyalty.vue")
            }, {
                path: "/ManageRedemption",
                name: "ManageRedemption",
                component: () => import ("@/components/Redemption/ManageRedemption")
            }, {
                path: "/ManageDigital",
                name: "ManageDigital",
                component: () => import ("@/components/Digital/ManageDigital")
            }, {
                path: "/timeslot",
                name: "timeslot",
                component: () => import ("@/components/Shared/TimeSlot")
            },
            // {
            // path: "/CreateProduct",
            // name: "CreateProduct",
            // component: () => import("@/components/Product/CreateProduct.vue"),
            // },
            // {
            // path: "/viewProduct",
            // name: "viewProduct",
            // component: () => import("@/components/Product/viewProduct.vue"),
            // },
            // {
            // path: "/EditProduct",
            // name: "EditProduct",
            // component: () => import("@/components/Product/EditProduct.vue"),
            // },
            // {
            // path: "/ManageExpertToPro",
            // name: "ManageExpertToPro",
            // component: () => import("@/components/User/ManageExpertToPro"),
            // },
            // {
            // path: "/ManageUserToPro",
            // name: "ManageUserToPro",
            // component: () => import("@/components/User/ManageUserToPro"),
            // },
            {
                path: "/ManageUser",
                name: "ManageUser",
                component: () => import ("@/views/ManageUser")
            }, {
                path: "/viewUser",
                name: "viewUser",
                component: () => import ("@/components/User/viewUser.vue")
            },
            // {
            // path: "/ManageUserActive",
            // name: "ManageUserActive",
            // component: () => import("@/views/ManageUserActive"),
            // },
            // {
            // path: "/viewUserActive",
            // name: "viewUserActive",
            // component: () => import("@/components/UserActive/viewUserActive.vue"),
            // },
            // {
            // path: "/ManageOrder",
            // name: "ManageOrder",
            // component: () => import("@/views/ManageOrder"),
            // },
            // {
            // path: "/viewOrder",
            // name: "viewOrder",
            // component: () => import("@/components/Order/viewOrder.vue"),
            // },
            // {
            // path: "/EditOrder",
            // name: "EditOrder",
            // component: () => import("@/components/Order/EditOrder.vue"),
            // },
            {
                path: "/Dashboard",
                name: "Dashboard",
                component: () => import ("@/views/Dashboard")
            },
            // {
            // path: "/ManageCoupon",
            // name: "ManageCoupon",
            // component: () => import("@/views/ManageCoupon"),
            // },
            // {
            // path: "/CreateCoupon",
            // name: "CreateCoupon",
            // component: () => import("@/components/Coupon/CreateCoupon.vue"),
            // },
            // {
            // path: "/ViewCoupon",
            // name: "ViewCoupon",
            // component: () => import("@/components/Coupon/ViewCoupon.vue"),
            // },
            // {
            // path: "/EditCoupon",
            // name: "EditCoupon",
            // component: () => import("@/components/Coupon/EditCoupon.vue"),
            // },
            // {
            // path: "/ManageVoucher",
            // name: "ManageVoucher",
            // component: () => import("@/views/ManageVoucher"),
            // },
            // {
            // path: "/CreateVoucher",
            // name: "CreateVoucher",
            // component: () => import("@/components/Voucher/CreateVoucher.vue"),
            // },
            // {
            // path: "/ViewVoucher",
            // name: "ViewVoucher",
            // component: () => import("@/components/Voucher/ViewVoucher.vue"),
            // },
            // {
            // path: "/EditVoucher",
            // name: "EditVoucher",
            // component: () => import("@/components/Voucher/EditVoucher.vue"),
            // },
            // {
            // path: "/Manageguidance",
            // name: "Manageguidance",
            // component: () => import("@/views/ManageGuidance"),
            // },
            // {
            // path: "/Createguidance",
            // name: "Createguidance",
            // component: () => import("@/components/guidance/Createguidance.vue"),
            // },
            // {
            // path: "/Viewguidance",
            // name: "Viewguidance",
            // component: () => import("@/components/guidance/Viewguidance.vue"),
            // },
            // {
            // path: "/Editguidance",
            // name: "Editguidance",
            // component: () => import("@/components/guidance/Editguidance.vue"),
            // },
            // {
            // path: "/Managenotification",
            // name: "Managenotification",
            // component: () => import("@/views/ManageNotification"),
            // },
            // {
            // path: "/Createnotification",
            // name: "Createnotification",
            // component: () =>
            //     import("@/components/notification/Createnotification.vue"),
            // },
            // {
            // path: "/Viewnotification",
            // name: "Viewnotification",
            // component: () =>
            //     import("@/components/notification/Viewnotification.vue"),
            // },
            // {
            // path: "/Editnotification",
            // name: "Editnotification",
            // component: () =>
            //     import("@/components/notification/Editnotification.vue"),
            // },
            // {
            // path: "/Managejob",
            // name: "Managejob",
            // component: () => import("@/views/Managejob"),
            // },
            // {
            // path: "/Createjob",
            // name: "Createjob",
            // component: () => import("@/components/job/Createjob.vue"),
            // },
            // {
            // path: "/Viewjob",
            // name: "Viewjob",
            // component: () => import("@/components/job/Viewjob.vue"),
            // },
            // {
            // path: "/Editjob",
            // name: "Editjob",
            // component: () => import("@/components/job/Editjob.vue"),
            // },
            // {
            // path: "/Managelicenses",
            // name: "Managelicenses",
            // component: () => import("@/views/Managelicenses"),
            // },
            // {
            // path: "/Createlicenses",
            // name: "Createlicenses",
            // component: () => import("@/components/licenses/Createlicenses.vue"),
            // },
            // {
            // path: "/Viewlicenses",
            // name: "Viewlicenses",
            // component: () => import("@/components/licenses/Viewlicenses.vue"),
            // },
            // {
            // path: "/Editlicenses",
            // name: "Editlicenses",
            // component: () => import("@/components/licenses/Editlicenses.vue"),
            // },
            // {
            // path: "/Manageskills",
            // name: "Manageskills",
            // component: () => import("@/views/Manageskills"),
            // },
            // {
            // path: "/Createskills",
            // name: "Createskills",
            // component: () => import("@/components/skills/Createskills.vue"),
            // },
            // {
            // path: "/Viewskills",
            // name: "Viewskills",
            // component: () => import("@/components/skills/Viewskills.vue"),
            // },
            // {
            // path: "/Editskills",
            // name: "Editskills",
            // component: () => import("@/components/skills/Editskills.vue"),
            // },
            // {
            // path: "/Report1",
            // name: "Report1",
            // component: () => import("@/components/Report/Report1.vue"),
            // },
            {
                path: "/ManageOC",
                name: "ManageOC",
                component: () => import ("@/views/ManageOC")
            }, {
                path: "/CreateOC",
                name: "CreateOC",
                component: () => import ("@/components/OnlineConsultant/CreateOC.vue")
            }, {
                path: "/ViewOC",
                name: "ViewOC",
                component: () => import ("@/components/OnlineConsultant/ViewOC.vue")
            }, {
                path: "/EditOC",
                name: "EditOC",
                component: () => import ("@/components/OnlineConsultant/EditOC.vue")
            }, {
                path: "/Setting",
                name: "Setting",
                component: () => import ("@/views/Setting.vue")
            }, {
                path: "/ManageAP",
                name: "ManageAP",
                component: () => import ("@/views/ManageAP")
            }, {
                path: "/CreateAP",
                name: "CreateAP",
                component: () => import ("@/components/Appointment/CreateAP.vue")
            }, {
                path: "/ViewAP",
                name: "ViewAP",
                component: () => import ("@/components/Appointment/ViewAP.vue")
            }, {
                path: "/EditAP",
                name: "EditAP",
                component: () => import ("@/components/Appointment/EditAP.vue")
            }, {
                path: "/Setting",
                name: "Setting",
                component: () => import ("@/views/Setting.vue")
            },
        ]
    },
];

const router = new VueRouter({mode: "history", base: process.env.BASE_URL, routes});

export default router;
