<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7">Digital Assets Hub</div>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab" active-class="active" class="mx-auto">
          <v-tab
            v-for="item in tabItems"
            :key="item.tab"
            style="font-weight: 600; width: 556px"
            class="fn14 mx-auto"
          >
            <div class="text-left">{{ item.tab }}</div>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabItems" :key="item.tab" class="ma-2">
            <v-col cols="12">
              <v-row>
                <v-col cols="4" class="mr-4">
                  <v-text-field
                    class="mb-4"
                    v-model="search"
                    dense
                    hide-details
                    outlined
                    placeholder="ค้นหา"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right">
                  <v-btn
                    v-if="tab === 0"
                    dark
                    class="rounded-xl"
                    style="
                      background: linear-gradient(268.1deg, #07204b, #1a347f);
                    "
                    @click="dialogStepFormOpen()"
                  >
                    <span class="text-center mx-4 fn14-weight7">
                      <a-icon type="plus" class="mr-2" />
                      Create New Post
                    </span>
                  </v-btn>
                  <stepForm
                    :dialog="dialogPost"
                    :form.sync="form"
                    :isEdit="isEdit"
                    :items="itemEdit"
                    @reloadTable="reloadTable"
                    @clear="clear"
                    @close="closeDialog"
                    v-if="tab === 0"
                  />
                  <stepFormCategory
                    :dialog="dialog"
                    :form.sync="form"
                    @getAllCate1="getAllCate1"
                    v-if="tab === 1"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-card v-if="item.tab === 'Posts'" class="rounded-lg">
              <v-data-table
                :headers="item.headers"
                :items="items"
                :search="search"
                disable-pagination
                hide-default-footer
                class="elevation-1"
                style="font-weight: 700"
              >
                <template v-slot:header.digital_name="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.digitalImg="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>

                <template v-slot:[`item.digitalImg`]="{ item }">
                  <!-- <video
                    v-if="item.type === 'video'"
                    style="width: 120px; height: auto"
                    
                  >
                    <source :src="item.digitalImg" />
                  </video> -->
                  <v-img
                    :src="item.digitalImg"
                    width="120px"
                    class="ma-1"
                  ></v-img>
                </template>
                <template
                  v-slot:header.digital_category_1.category_name="{ header }"
                >
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.drug="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.nexgard="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <!-- <template v-slot:header.type="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template> -->
                <!-- <template v-slot:header.point="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template> -->
                <template v-slot:header.view="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.status="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:header.action="{ header }">
                  <span class="fn14-weight7 black--text">{{
                    header.text
                  }}</span>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div class="fn14-weight7">{{ item.name }}</div>
                  <div>{{ item.time }}</div>
                </template>
                <template v-slot:[`item.image`]="{ item }">
                  <v-img :src="item.image" width="150" />
                </template>
<!-- 
                <template v-slot:[`item.point`]="{ item }">
                  <div class="fn14-weight7" style="color: #384e85">
                    {{ item.point }}
                  </div>
                </template> -->
                <template v-slot:[`item.view`]="{ item }">
                  <div class="fn14-weight7" style="color: #384e85">
                    {{ item.view }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-switch
                    v-if="item.status === 'true' || item.status === true"
                    inset
                    color="#1A347F"
                    v-model="item.status"
                    @change="Status(item)"
                  />
                  <v-switch
                    v-if="item.status === '' || item.status === false"
                    inset
                    color="#1A347F"
                    v-model="item.status"
                    @change="Status(item)"
                  />
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-row justify="center" class="my-2">
                    <!-- <v-icon @click="Viewbanners(item)">mdi-eye</v-icon> -->
                    <v-btn
                      @click="Updatedigital(item)"
                      dark
                      outlined
                      class="rounded-xl fn12-weight7 mb-2 mx-1"
                      style="color: #4f4f4f; width: 100px"
                    >
                      Edit
                    </v-btn>
                    <v-btn
                      @click="Deletedigital(item)"
                      dark
                      outlined
                      class="rounded-xl fn12-weight7 mx-1"
                      style="color: #4f4f4f; width: 100px"
                    >
                      Delete
                    </v-btn>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
            <!-- วาป -->
            <v-card elevation="0" v-if="item.tab === 'Category'">
              <v-row justify="end">
                <v-btn
                  dark
                  outlined
                  class="rounded-xl fn12-weight7 mx-1 mr-5"
                  style="color: #4f4f4f"
                  @click="opendialogSubcate2()"
                >
                  <a-icon type="plus" class="mr-2" />Add Sub Category</v-btn
                >
                <v-btn
                  dark
                  outlined
                  class="rounded-xl fn12-weight7 mx-1 mr-5"
                  style="color: #4f4f4f"
                  @click="opendialogSubcate3()"
                >
                  <a-icon type="plus" class="mr-2" />Add Sub Sub Category</v-btn
                >
                <!-- </v-row>
              <v-row justify="end" class="pt-2"> -->
                <v-btn
                  dark
                  outlined
                  class="rounded-xl fn12-weight7 mx-1 mr-5"
                  style="color: blue"
                  @click="opendialogedit()"
                >
                  <!-- @click="DeleteCate(item)" -->
                  <a-icon type="plus" class="mr-2" />Edit Category</v-btn
                >
                <v-btn
                  dark
                  outlined
                  class="rounded-xl fn12-weight7 mx-1 mr-5"
                  style="color: red"
                  @click="opendialogdelete()"
                >
                  <!-- @click="DeleteCate(item)" -->
                  <a-icon type="plus" class="mr-2" />Delete Category</v-btn
                >
              </v-row>
              <div>
                <v-row>
                  <v-col cols="9">
                    <v-treeview
                      hoverable
                      activatable
                      :open="open"
                      :items="cate1"
                    >
                      <!-- <template v-slot:label="{ item }">
                        <v-menu :value="showMenu" offset-x>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              flat
                              :ripple="false"
                              class="ma-0 pa-0 transparent"
                              elevation="0"
                              v-on="on"
                            >
                              {{ item.category_name }}
                            </v-btn>
                          </template>
                          <v-list class="pa-2">
                            <v-list-tile
                              v-for="menuItem in menuItems"
                              :key="menuItem"
                            >
                              <div class="mx-auto my-2">
                                <v-btn
                                  dark
                                  outlined
                                  class="rounded-xl fn12-weight7 mx-1"
                                  style="color: #4f4f4f; width: 150px"
                                  @click="btnName(menuItem, item)"
                                  >{{ menuItem }}</v-btn
                                >
                              </div>
                            </v-list-tile>
                          </v-list>
                        </v-menu>
                      </template> -->
                    </v-treeview>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <v-dialog v-model="dialogEditCate1" :retain-focus="false">
              <v-card>
                <v-row class="pa-4" style="background: white">
                  <v-col cols="8" class="mx-auto">
                    <div class="fn20-weight7">Edit Category</div>
                  </v-col>

                  <v-col cols="8" class="mx-auto">
                    <v-text-field
                      v-model="editCate1.category_name"
                      hide-details
                      outlined
                      dense
                      placeholder="Category Name*"
                    />
                  </v-col>
                  <v-col cols="8" class="mx-auto">
                    <v-switch
                      v-model="form.isvet"
                      inset
                      color="#1A347F"
                      label="แสดงเฉพาะสัตว์แพทย์เท่านั้น"
                    />
                  </v-col>
                  <v-col cols="12" class="pa-0" align="center" justify="center">
                    <v-btn
                      class="rounded-xl mr-2"
                      style="color: #9bbef2"
                      outlined
                      width="250"
                      @click="dialogEditCate1 = false"
                    >
                      <span class="text-center fn14-weight7"> cancel </span>
                    </v-btn>
                    <v-btn
                      :dark="form.name === '' ? false : true"
                      class="rounded-xl"
                      :style="
                        form.name === ''
                          ? ''
                          : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
                      "
                      width="250"
                      @click="submitCate1(editCate1)"
                    >
                      <!-- @click="next" -->
                      <span class="text-center fn14-weight7"> Save </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEditCate2" :retain-focus="false">
              <v-card>
                <v-row class="pa-4" style="background: white">
                  <v-col cols="8" class="mx-auto">
                    <div class="fn20-weight7">Sub Category</div>
                  </v-col>

                  <v-col cols="8" class="mx-auto">
                    <v-select
                      v-model="cate2.digitalCategory1Id"
                      :items="cate1"
                      item-text="category_name"
                      item-value="id"
                      label="Category 1"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="mx-auto">
                    <v-text-field
                      v-model="cate2.category_name"
                      hide-details
                      outlined
                      dense
                      placeholder="Category Name*"
                    />
                  </v-col>

                  <v-col cols="12" class="pa-0" align="center" justify="center">
                    <v-btn
                      class="rounded-xl mr-2"
                      style="color: #9bbef2"
                      outlined
                      width="250"
                      @click="closedialogEditCate2()"
                    >
                      <!-- @click="dialogEditCate2 = false" -->
                      <span class="text-center fn14-weight7"> cancel </span>
                    </v-btn>
                    <v-btn
                      class="rounded-xl"
                      dark
                      style="
                        background: linear-gradient(268.1deg, #07204b, #1a347f);
                      "
                      width="250"
                      @click="addsubcate2(cate2)"
                    >
                      <span class="text-center fn14-weight7"> Save </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEditCate3" :retain-focus="false">
              <v-card>
                <v-row class="pa-4" style="background: white">
                  <v-col cols="8" class="mx-auto">
                    <div class="fn20-weight7">Sub Sub Category</div>
                  </v-col>
                  <v-col cols="8" class="mx-auto">
                    <v-select
                      v-model="cate3.digitalCategory1Id"
                      :items="cate1Item"
                      item-text="category_name"
                      item-value="id"
                      label="Category 1"
                      outlined
                      dense
                      @change="getOneCate2(cate3.digitalCategory1Id)"
                      @click="getOneCate1()"
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    cols="8"
                    class="mx-auto"
                    v-if="cate3.digitalCategory1Id"
                  >
                    <v-select
                      v-model="cate3.digitalCategory2Id"
                      :items="cate2Item"
                      item-text="category_name"
                      item-value="id"
                      label="Category 2"
                      outlined
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="mx-auto">
                    <v-text-field
                      v-model="cate3.category_name"
                      hide-details
                      outlined
                      dense
                      placeholder="Category Name*"
                    />
                  </v-col>

                  <v-col cols="12" class="pa-0" align="center" justify="center">
                    <v-btn
                      class="rounded-xl mr-2"
                      style="color: #9bbef2"
                      outlined
                      width="250"
                      @click="editdialogEditCate3()"
                    >
                      <span class="text-center fn14-weight7"> cancel </span>
                    </v-btn>
                    <v-btn
                      class="rounded-xl"
                      dark
                      style="
                        background: linear-gradient(268.1deg, #07204b, #1a347f);
                      "
                      width="250"
                      @click="addsubcate3(cate3)"
                    >
                      <span class="text-center fn14-weight7"> Save </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogdelete" width="80vw" :retain-focus="false">
              <v-card>
                <v-container>
                  <v-row align="center" justify="center">
                    <v-col cols="12" class="pb-0">Choose Category*:</v-col>
                    <v-col cols="8" class="pb-0">
                      <v-select
                        v-model="formdelete.digitalCategory1Id"
                        item-text="category_name"
                        item-value="id"
                        hide-details
                        outlined
                        dense
                        :items="cate1"
                        placeholder="Select Category"
                        @change="getCategory2(formdelete.digitalCategory1Id)"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="8"
                      class="pb-0"
                      v-if="formdelete.digitalCategory1Id"
                    >
                      <v-select
                        v-model="formdelete.digitalCategory2Id"
                        item-text="category_name"
                        item-value="id"
                        hide-details
                        outlined
                        dense
                        :items="cate2"
                        placeholder="Select Sub Category"
                        @change="getCategory3(formdelete.digitalCategory2Id)"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="8"
                      class="pb-0"
                      v-if="formdelete.digitalCategory2Id"
                    >
                      <v-select
                        v-model="formdelete.digitalCategory3Id"
                        item-text="category_name"
                        item-value="id"
                        hide-details
                        outlined
                        dense
                        :items="cate3"
                        placeholder="Select Sub Sub Category"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6 mb-2">
                    <v-col
                      cols="12"
                      class="pa-0"
                      align="center"
                      justify="center"
                    >
                      <v-btn
                        class="rounded-xl mr-2"
                        style="color: #9bbef2"
                        outlined
                        width="250"
                        @click="cleardialogdelete()"
                      >
                        <span class="text-center fn14-weight7"> cancel </span>
                      </v-btn>
                      <v-btn
                        class="rounded-xl"
                        dark
                        outlined
                        color="red"
                        width="250"
                        @click="DeleteCate(formdelete)"
                      >
                        <span class="text-center fn14-weight7"> Delete </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogedit" width="80vw" :retain-focus="false">
              <v-card>
                <v-container>
                  <v-row align="center" justify="center">
                    <v-col cols="12" class="pb-0">Choose Category*:</v-col>
                    <v-col cols="8" class="pb-0">
                      <v-select
                        v-model="formdelete.digitalCategory1Id"
                        item-text="category_name"
                        item-value="id"
                        hide-details
                        outlined
                        dense
                        :items="cate1"
                        placeholder="Select Category"
                        @change="getCategory2(formdelete.digitalCategory1Id)"
                        :rules="rules.required"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="8"
                      class="pb-0"
                      v-if="formdelete.digitalCategory1Id"
                    >
                      <v-select
                        v-model="formdelete.digitalCategory2Id"
                        item-text="category_name"
                        item-value="id"
                        hide-details
                        outlined
                        dense
                        :items="cate2"
                        placeholder="Select Sub Category"
                        @change="getCategory3(formdelete.digitalCategory2Id)"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="8"
                      class="pb-0"
                      v-if="formdelete.digitalCategory2Id"
                    >
                      <v-select
                        v-model="formdelete.digitalCategory3Id"
                        item-text="category_name"
                        item-value="id"
                        hide-details
                        outlined
                        dense
                        :items="cate3"
                        placeholder="Select Sub Sub Category"
                      ></v-select>
                    </v-col>

                    <v-col cols="8" class="pb-0">
                      <span class="fn14">กรุณากรอกข้อมูลที่ต้องการแก้ไข</span>
                      <v-text-field
                        v-model="formdelete.editText"
                        hide-details
                        outlined
                        dense
                        placeholder=""
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6 mb-2">
                    <v-col
                      cols="12"
                      class="pa-0"
                      align="center"
                      justify="center"
                    >
                      <v-btn
                        class="rounded-xl mr-2"
                        style="color: #9bbef2"
                        outlined
                        width="250"
                        @click="cleardialogdelete()"
                      >
                        <span class="text-center fn14-weight7"> cancel </span>
                      </v-btn>
                      <v-btn
                        class="rounded-xl"
                        dark
                        outlined
                        color="red"
                        width="250"
                        @click="EditCate(formdelete)"
                      >
                        <span class="text-center fn14-weight7"> Edit </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
import stepForm from "@/components/Shared/stepFormDigital";
import stepFormCategory from "@/components/Shared/stepFormCategoryDigital";
export default {
  components: {
    stepForm,
    stepFormCategory,
  },
  data() {
    return {
      dialogPost: false,
      showSelect: "",
      open: ["public"],
      tree: [],
      items: [],
      model: 1,
      tab: null,
      tabItems: [
        {
          tab: "Posts",
          headers: [
            {
              text: "Title Name",
              value: "digital_name",
              align: "start",
              width: "200",
            },
            {
              text: "Images",
              value: "digitalImg",
              align: "center",
              width: "auto",
            },
            {
              text: "Category",
              value: "digital_category_1.category_name",
              align: "center",
              width: "auto",
            },
            {
              text: "",
              value: "digital_category_2.category_name",
              align: "center",
              width: "auto",
            },
            {
              text: "",
              value: "digital_category_3.category_name",
              align: "center",
              width: "auto",
            },
            // { text: "type", value: "type", width: "100" },
            // { text: "", value: "nexgard" },
            // { text: "Points", value: "point", width: "100" },
            { text: "Views", value: "view", width: "100" },
            { text: "Status", value: "status", width: "100" },
            { text: "Action", value: "action", align: "center" },
          ],

          items: [
            // {
            //   name: "Learning Hub Intro-duction 101 / 2022",
            //   time: "Sep 8, 2012 at 10:05 PM",
            //   image: require("@/assets/images/digital.png"),
            //   category: "Technical digital",
            //   drug: "Parasiticide",
            //   nexgard: "Nexgard Family",
            //   point: "30",
            //   view: "2000",
            //   status: true,
            // },
          ],
        },

        {
          tab: "Category",
        },
      ],

      menuItems: ["add sub Category", "edit", "delete"],
      formdelete: {},
      form: {
        // "category_name":"digital Category 1 TEST3",
        // "detail":"digital Category TEST",
        // "create_by":"ADMIN",
        // "update_by":"ADMIN"
        /////////////
        // type: null,
        // digital_name: "",
        // digitalCategory3Id: "",
        // point: "",
        // digitalImg: "",
        /////////////////
        // date: new Date().toISOString().substr(0, 10),
        // picture_1: "",
        // picture_2: "",
        // menufrom: false,
        // publish: false,
        // status: true,
        // categoryName: "",
      },
      rules: {
        required: [(v) => !!v || "กรุณาเลือกข้อมูล"],
      },

      search: "",
      count: 0,

      itemEdit: {},
      isEdit: "",
      dialogEditCate1: false,

      cate1: [],
      editCate1: [],
      dialogEditCate2: false,
      cate2: [],
      editCate2: [],
      dialogEditCate3: false,
      cate3: [],
      editCate3: [],
      cate2Item: [],
      dialogdelete: false,
      cate1Item: [],
      dialogedit: false,
      selectedType: "",
    };
  },
  async created() {
    this.selectedType = JSON.parse(
      Decode.decode(localStorage.getItem("selectedType"))
    );
    console.log("selectedType", this.selectedType);
    var user = JSON.parse(
      Decode.decode(localStorage.getItem("userBiAdmin"))
    );
    console.log("token", user.token);
    await this.getAllDigitalAssets();
    await this.getAllCate1();
  },
  methods: {
    editdialogEditCate3() {
      this.cate3.digitalCategory1Id = "";
      this.cate3.digitalCategory2Id = "";
      this.cate3.category_name = "";
      this.dialogEditCate3 = false;
    },
    closedialogEditCate2() {
      this.cate2.digitalCategory1Id = "";
      this.cate2.category_name = "";
      this.dialogEditCate2 = false;
    },
    opendialogdelete() {
      this.dialogdelete = true;
    },
    async getOneCate1(item) {
      console.log("item2", item);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/digital_category_1?usertype=${this.selectedType}`
      );
      console.log("response cate 1", response.data.data);
      this.cate1Item = response.data.data;
    },
    async getOneCate2(item) {
      console.log("item2", item);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/digital_category_2?digitalCategory1Id=${item}`
      );
      console.log("response cate 2", response.data.data);
      this.cate2Item = response.data.data;
    },
    async getAllCate1() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/digital_category_1/categoryTree?usertype=${this.selectedType}`
      );
      console.log("digital_category_1categoryTree", response.data);
      this.cate1 = response.data.data;
    },
    async getCategory2(item) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/digital_category_2?digitalCategory1Id=${item}`
      );
      console.log("cate2", response.data);
      this.cate2 = response.data.data;
    },
    async getCategory3(item) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/digital_category_3?digitalCategory2Id=${item}`
      );
      console.log("cate3", response.data);
      this.cate3 = response.data.data;
    },
    async submitCate1(val) {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/digital_category_1/${val.id}`,
        val,
        auth
      );
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขหมวดหมู่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        // this.getAllCate1();
        await location.reload();
        this.dialogEditCate1 = false;
      } else {
        this.$swal.fire({
          icon: "error",
          text: `แก้ไขหมวดหมู่ไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async btnName(name, item) {
      console.log("item", item);
      if (name === "edit") {
        this.showSelect = item;
        console.log("edit", name, item);
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/digital_category_1/${item.id}`
        );
        console.log("response", response.data.data);
        this.editCate1 = response.data.data;
        this.dialogEditCate1 = true;
        // } else if ((name = "add sub Category")) {
        //   this.dialogEditCate2 = true;
        //   this.cate2.digitalCategory1Id = item.id;
      }
    },
    opendialogedit() {
      this.dialogedit = true;
    },
    opendialogSubcate2() {
      this.dialogEditCate2 = true;
    },
    opendialogSubcate3() {
      this.dialogEditCate3 = true;
    },
    async addsubcate2(item) {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const data = {
        digitalCategory1Id: item.digitalCategory1Id,
        category_name: item.category_name,
        // detail: "digital Category TEST",
        // create_by: "ADMIN",
        // update_by: "ADMIN",
      };
      console.log("data", data);
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/digital_category_2`,
        data,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `สร้างหมวดหมู่2สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        // this.getAllCate1();
        await location.reload();
        this.dialogEditCate2 = false;
      } else {
        this.$swal.fire({
          icon: "error",
          text: `สร้างหมวดหมู่2ไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async addsubcate3(item) {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const data = {
        digitalCategory1Id: item.digitalCategory1Id,
        digitalCategory2Id: item.digitalCategory2Id,
        category_name: item.category_name,
        // detail: "digital Category TEST",
        // create_by: "ADMIN",
        // update_by: "ADMIN",
      };
      console.log("data", data);
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/digital_category_3`,
        data,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `สร้างหมวดหมู่3สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        // this.getAllCate1();
        await location.reload();
        this.dialogEditCate3 = false;
      } else {
        this.$swal.fire({
          icon: "error",
          text: `สร้างหมวดหมู่3ไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    async getAllDigitalAssets() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/digital?usertype=${this.selectedType}`
      );
      console.log("getAllDigitalAssets", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    Updatedigital(val) {
      localStorage.setItem("tipsdata", Encode.encode(val));
      this.dialogPost = true;
      this.isEdit = "edit";
      this.itemEdit = val;
      // this.$router.push("Editdigital");
    },
    Viewdigital(val) {
      localStorage.setItem("tipsdata", Encode.encode(val));
      this.$router.push("Viewdigital");
    },
    async Deletedigital(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log("val delete", val);
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiAdmin"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/digital/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllDigitalAssets();
        }
      });
    },
    cleardialogdelete() {
      // dialogedit;
      // formdelete.digitalCategory1Id;
      // formdelete.digitalCategory2Id;
      // formdelete.digitalCategory3Id;
      // formdelete.editText;
      this.formdelete = {};
      this.dialogdelete = false;
      this.dialogedit = false;
    },
    async EditCate(val) {
      if (val.digitalCategory3Id) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiAdmin"))
        );
        const auth = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };
        const data = {
          category_name: val.editText,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/digital_category_3/${val.digitalCategory3Id}`,
          data,
          auth
        );
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขหมวดหมู่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          // this.getAllCate1();
          // await location.reload();
          this.dialogEditCate1 = false;
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขหมวดหมู่ไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else if (val.digitalCategory2Id) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiAdmin"))
        );
        const auth = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };
        const data = {
          category_name: val.editText,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/digital_category_2/${val.digitalCategory2Id}`,
          data,
          auth
        );
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขหมวดหมู่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          // this.getAllCate1();
          // await location.reload();
          this.dialogEditCate1 = false;
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขหมวดหมู่ไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else if (val.digitalCategory1Id) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiAdmin"))
        );
        const auth = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };
        const data = {
          category_name: val.editText,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/digital_category_1/${val.digitalCategory1Id}`,
          data,
          auth
        );
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขหมวดหมู่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          // this.getAllCate1();
          await location.reload();
          this.dialogEditCate1 = false;
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขหมวดหมู่ไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async DeleteCate(val) {
      console.log("val", val);
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log("val delete", val);
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiAdmin"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          };
          if (val.digitalCategory3Id) {
            const response = await this.axios.delete(
              `${process.env.VUE_APP_API}/digital_category_3/` +
                val.digitalCategory3Id,
              auth
            );
            console.log(response);

            await this.$swal.fire({
              icon: "success",
              text: "ลบข้อมูลสำเร็จ",
              showConfirmButton: false,
              timer: 1000,
            });
            this.getAllCate1();
            // await location.reload();
            this.dialogdelete = false;
          } else if (val.digitalCategory2Id) {
            const response = await this.axios.delete(
              `${process.env.VUE_APP_API}/digital_category_2/` +
                val.digitalCategory2Id,
              auth
            );
            console.log(response);

            await this.$swal.fire({
              icon: "success",
              text: "ลบข้อมูลสำเร็จ",
              showConfirmButton: false,
              timer: 1000,
            });
            this.getAllCate1();
            // await location.reload();
            this.dialogdelete = false;
          } else if (val.digitalCategory1Id) {
              const response = await this.axios.delete(
                `${process.env.VUE_APP_API}/digital_category_1/` +
                  val.digitalCategory1Id,
                auth
              );
              console.log(response);

            // await this.$swal.fire({
            //   icon: "error",
            //   text: "ไม่สามารถลบ Category หลัก ได้",
            //   showConfirmButton: false,
            //   timer: 1000,
            // });
              this.getAllCate1();
            //   // await location.reload();
              this.dialogdelete = false;
          }
        }
      });
    },
    dialogStepFormOpen() {
      this.clear();
      this.itemEdit = null;
      this.dialogPost = true;
    },
    closeDialog() {
      this.dialogPost = false;
      this.itemEdit = null;
      this.isEdit = "";
    },
    clear() {
      this.form = {
        type: null,
        digital_name: "",
        digitalCategory3Id: "",
        // point: "",
        digitalImg: "",
      };
    },
    reloadTable() {
      this.getAllDigitalAssets();
    },
    async Status(val) {
      console.log("item", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      if (val.status) {
        val.status = true;
      } else {
        val.status = "";
      }
      const data = {
        digital_name: val.digital_name,
        status: val.status,
      };
      console.log("item", val.id);
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/digital/${val.id}`,
        data,
        auth
      );
    },
  },
};
</script>

<style scoped>
.active {
  color: #1a347f !important;
  background-color: #edefff !important;
}
.v-table td tr th:hover:not(.v-table__expanded__content) {
  background: rgb(255, 255, 255) !important;
}
</style>
</style>
